import { projectStorage } from "@config/firebase/config";
import React, { ChangeEvent } from "react";
import toast from "react-hot-toast";

export type Files<T = any> = {
  [key in keyof T]?: FilesItem;
};

export type FilesItem = {
  file: File;
  path: string;
};

const defaultFileSize = 2 * 1024 * 1024;

// const onFileSelect = <T = any>(
//   onAddFile: React.Dispatch<React.SetStateAction<Files>>,
//   onSave: React.Dispatch<React.SetStateAction<T>>,
//   destination: "File/",
//   maxFileSize: number = defaultFileSize
// ) => (e: ChangeEvent<HTMLInputElement>) => {
//   if (e.target.files && e.target.files[0]) {
//     const file = e.target.files[0];
//     const key = e.target.name;
//     if (file.size > maxFileSize) {
//       toast.error("File is larger than 2mb", {
//         style: {
//           backgroundColor: "red",
//           color: "#fff",
//         },
//       });
//       return;
//     }
//     onAddFile((files) => ({
//       ...files,
//       [e.target.name]: {
//         file,
//         path: URL.createObjectURL(file),
//       },
//     }));
//     uploadFile(file, key, destination, onSave);
//   }
// };

// const uploadFile = async <T = any>(
//   file: File,
//   key: string,
//   destination:,
//   onSave: React.Dispatch<React.SetStateAction<T>>
// ) => {

//   toast.promise(promise, {
//     loading: "Uploading image...",
//     error: (e) => e.message,
//     success: (res) => {
//       const url = res.data.fileUrl;
//       onSave((state) => ({ ...state, [key]: url }));
//       return "Uploaded Successfully";
//     },
//   });
// };

export const uploadAsync = async (
  { target: { files } }: ChangeEvent<HTMLInputElement>,
  onAddFile: (fileData: { file: File; path: string }) => void,
  destination: string = "File/",
  maxFileSize: number = 1024 * 1024 * 2
) => {
  if (files && files[0]) {
    const file = files[0];
    if (file.size > maxFileSize) {
      toast.error("File is larger than 2mb", {
        style: {
          backgroundColor: "red",
          color: "#fff",
        },
      });
      throw new Error("File too large");
    }
    onAddFile({ file, path: URL.createObjectURL(file) });
    return uploadFileAsync(file, destination);
  }
};

export const uploadFileAsync = async (file: File, destination: string) => {
  const ref = projectStorage.ref(destination + Date.now());
  const promise: Promise<string> = ref
    .put(file)
    .then((snap) => snap.ref.getDownloadURL());

  toast.promise(promise, {
    loading: "Uploading image...",
    error: (e) => e.message,
    success: "Uploaded Successfully",
  });
  return promise;
};

export default uploadAsync;
