import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import { useSelector } from "react-redux";

import { makeStyles } from "@material-ui/core";
import Button from "./comps/layout/Button";
import InnerNav from "./comps/layout/InnerNav";
import Nav from "./comps/layout/Nav";
import LowerThirdsGrid from "./views/lowerthirds/LowerThirdsGridPage";
import CastersGridPage from "./views/casters/CastersGridPage";
import { ReduxState } from "./config/types/types";
import Tournaments from "./views/tournaments/Tournaments";
import ParticipantsPage from "./views/participants/ParticipantsGridPage";
import MatchesPage from "./views/matches/MatchesGridPage";
import StatsPage from "./views/stats/StatsGridPage";
import WebsocketDialog from "./comps/connection/WebsocketDialog";
import LinkPage from "./views/links/LinkPage";
import { wsContext } from "./config/websocket/WebsocketProvider";
import StatePage from "./views/state/StateGridPage";
import MatchesWidget from "./comps/widget/MatchesWidget";
import IngameGridPage from "./views/ingame/IngameGridPage";
import { Toaster } from "react-hot-toast";
import DrawShowPage from "./views/drawshow/DrawShowPage";
import TimelinePage from "./views/timeline/TimelinePage";
import AuthDialog from "@components/auth/AuthDialog";
import { useAuthState } from "react-firebase-hooks/auth";
import { projectAuth } from "@config/firebase/config";

const makeComponentStyles = makeStyles((theme) => ({
  app: {
    display: "flex",
    height: "100vh",
    width: "100vw",
    overflowX: "hidden",
  },
  mainPanel: {
    height: "100%",
    flex: 1,
    maxHeight: "100%",
    display: "flex",
    flexDirection: "column",

    "& .content": {
      flex: 1,
      padding: theme.spacing(6),
    },
  },
}));

function App() {
  const classes = makeComponentStyles();
  const ws = React.useContext(wsContext);
  const { tournament, websocket_users, match, matches_today } = useSelector(
    (state: ReduxState) => state.live
  );
  const [user] = useAuthState(projectAuth);

  const regex = /.*@acadarena\.com/;
  const isStaff = regex.test(user?.email);

  return (
    <div className={classes.app}>
      <Nav />
      <div className={classes.mainPanel}>
        {tournament ? (
          <>
            <InnerNav />
            <div className="content">
              <Switch>
                <Route exact path="/tournaments">
                  <Tournaments />
                </Route>
                <Route exact path="/participants">
                  <ParticipantsPage />
                </Route>
                <Route exact path="/matches">
                  <MatchesPage />
                </Route>
                <Route exact path="/schedule"></Route>
                <Route exact path="/casters">
                  <CastersGridPage />
                </Route>
                <Route exact path="/lowerthirds">
                  <LowerThirdsGrid />
                </Route>
                <Route exact path="/ingame">
                  <IngameGridPage />
                </Route>
                <Route exact path="/state">
                  <StatePage />
                </Route>
                <Route exact path="/stats">
                  <StatsPage />
                </Route>
                <Route exact path="/links">
                  <LinkPage />
                </Route>
                <Route exact path="/timeline">
                  <TimelinePage />
                </Route>
                <Route exact path="/drawshow">
                  <DrawShowPage />
                </Route>
              </Switch>
            </div>
            {(Boolean(match) || Boolean(matches_today?.length)) && (
              <MatchesWidget />
            )}
          </>
        ) : (
          /**
           * Requires user to select a tournament
           */
          <>
            <Redirect to="/tournaments" />
            <Tournaments />
          </>
        )}

        <WebsocketDialog
          /**
           * This dialog box will persist
           * if the user is not in websocket_users
           */
          open={
            websocket_users.findIndex((user) => user.id === ws.socket?.id) ===
              -1 &&
            (process.env.NODE_ENV === "development" || isStaff)
          }
        />
      </div>
      <Toaster />
      <AuthDialog />
    </div>
  );
}

export default App;
