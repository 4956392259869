import React, { MouseEvent } from "react";

import {
  makeStyles,
  Button,
  Dialog,
  ButtonBase,
  Fab,
  CircularProgress,
  Avatar,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import Sheet from "../../comps/sheet/Sheet";
import SheetBody from "../../comps/sheet/SheetBody";
import SheetHead from "../../comps/sheet/SheetHead";
import SheetHeadTitle from "../../comps/sheet/SheetHeadTitle";

import { useDocumentData } from "react-firebase-hooks/firestore";
import fbase, {
  projectFirestore as db,
  projectStorage,
} from "../../config/firebase/config";
import { useSelector } from "react-redux";
import SheetFooter from "../../comps/sheet/SheetFooter";
import TextField from "../../comps/textfield/TextField";

import AddIcon from "@material-ui/icons/Add";
import CheckIcon from "@material-ui/icons/Check";
import SaveOutlinedIcon from "@material-ui/icons/SaveOutlined";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import CloudOutlinedIcon from "@material-ui/icons/CloudOutlined";
import { green } from "@material-ui/core/colors";
import { DeleteForever } from "@material-ui/icons";
import { ReduxState } from "../../config/types/types";
import AddDialog from "./Add.dialog";
interface Caster {
  name: string;
  ign: string;
  photo: string;
  photo_url: string;
  photo_ref: string;
}
interface CastersProps {
  list: Caster[];
}

const makeComponentStyles = makeStyles((theme) => ({
  castersSheet: {},
  casters: {
    displayt: "flex",
    flexDirection: "column",
  },
  caster: {
    padding: theme.spacing(2, 3),
    marginBottom: theme.spacing(3),
    border: "1px solid rgba(0,0,0,.1)",
    borderRadius: 3,
    display: "flex",
    alignItems: "center",

    "& .info": {
      display: "flex",
      flexDirection: "column",
      margin: theme.spacing(0, 3),
      flex: 1,

      "& .name": {
        fontSize: 12,
      },

      "& .ign": {
        fontWeight: 400,
      },
    },
  },
  noCaster: { padding: theme.spacing(0, 0, 2, 0) },
}));

const Casters: React.FC = () => {
  const [form, setForm] = React.useState<CastersProps>({ list: [] });
  const [addState, setAddState] = React.useState<boolean>(false);
  const { tournament } = useSelector((state: ReduxState) => state.live);
  const tournamentRef = db.collection("tournaments").doc(tournament?.url);
  const [casters, loading, error] = useDocumentData<CastersProps>(
    tournamentRef.collection("live").doc("casters")
  );

  React.useEffect(() => {
    if (!casters) return;
    setForm(casters);
  }, [casters, setForm]);

  const classes = makeComponentStyles();

  const openEditDialog = () => {};

  const deleteCaster = (caster: Caster) => {
    tournamentRef
      .collection("live")
      .doc("casters")
      .update({
        list: fbase.firestore.FieldValue.arrayRemove(caster),
      });
  };

  return (
    <Sheet loading={loading}>
      <SheetHead color="green">
        <SheetHeadTitle>Casters Pool</SheetHeadTitle>
      </SheetHead>
      <SheetBody>
        <div className={classes.casters}>
          {casters?.list.length ? (
            casters?.list.map((caster) => (
              <div key={caster.ign} className={classes.caster}>
                <Avatar src={caster.photo_url} />
                <div className="info">
                  <div className="ign">{caster.ign}</div>
                  <div className="name">{caster.name}</div>
                </div>
                <MenuButton
                  caster={caster}
                  editCaster={openEditDialog}
                  deleteCaster={deleteCaster}
                />
              </div>
            ))
          ) : (
            <div className={classes.noCaster}>
              No caster Yet for this tournament
            </div>
          )}
        </div>

        <Button
          color="primary"
          variant="outlined"
          onClick={() => setAddState(true)}
        >
          Add
        </Button>
      </SheetBody>
      <SheetFooter>
        <CloudOutlinedIcon /> Saved Online
      </SheetFooter>
      <AddDialog
        open={addState}
        onClose={() => setAddState(false)}
        tournamentRef={tournamentRef}
      ></AddDialog>
    </Sheet>
  );
};

export default Casters;

interface MenuButtonProps {
  editCaster: () => void;
  deleteCaster: (caster: Caster) => void;
  caster: Caster;
}

const MenuButton: React.FC<MenuButtonProps> = ({
  caster,
  editCaster,
  deleteCaster,
}) => {
  const [anchorEl, setAnchorEl] =
    React.useState<HTMLButtonElement | null>(null);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <div>
      <IconButton
        size="small"
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
        transformOrigin={{
          horizontal: "right",
          vertical: "top",
        }}
      >
        <MenuItem onClick={() => deleteCaster(caster)}>
          <ListItemIcon>
            <DeleteForever />
          </ListItemIcon>
          <ListItemText>Delete {caster.ign}</ListItemText>
        </MenuItem>
      </Menu>
    </div>
  );
};
