enum CorsUri {
  default = "https://noki-cors.herokuapp.com/",
  asia = "https://servers.acadarena.com/cors/",
  us = "https://acadarena-servers-us1-cod8n.ondigitalocean.app/",
  noki = "https://noki-cors.herokuapp.com/",
}

export const cors = (url: string, server: CorsUri = CorsUri.noki) => {
  const regularExpression = /https\:\/\/(.*\.\w*)\/(.*)/g;

  const regexMatches = url.match(regularExpression);
  console.log(regexMatches);
  return regularExpression.test(url)
    ? url.replace(regularExpression, `${server}$1:443/$2`)
    : `${server}${url.replace("http://", "")}`;
};
