import {
  Button,
  makeStyles,
  Menu,
  ListSubheader,
  MenuItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
  Fab,
  // CircularProgress,
  SvgIcon,
  Typography,
  // Checkbox,
  // IconButton,
  MenuProps,
  Switch,
  FormControlLabel,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@material-ui/core";
import React, {
  Dispatch,
  FormEvent,
  SetStateAction,
  useState,
  VFC,
} from "react";
import { useSelector } from "react-redux";
import Sheet from "../../comps/sheet/Sheet";
import SheetBody from "../../comps/sheet/SheetBody";
import SheetFooter from "../../comps/sheet/SheetFooter";
import SheetHead from "../../comps/sheet/SheetHead";
import SheetHeadTitle from "../../comps/sheet/SheetHeadTitle";
import SheetSection from "../../comps/sheet/SheetSection";
import CheckIcon from "@material-ui/icons/Check";
import SaveOutlinedIcon from "@material-ui/icons/SaveOutlined";
// import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import { ReactComponent as LogitechLogo } from "../../comps/assets/logitechG.svg";
import { green } from "@material-ui/core/colors";
import {
  Live,
  LogitechMVPProps,
  Participant,
  PlayerStatProps,
  ReduxState,
  Stat,
} from "../../config/types/types";
import {
  WebsocketProps,
  wsContext,
} from "../../config/websocket/WebsocketProvider";
import SheetHeadSub from "../../comps/sheet/SheetHeadSub";
import TextField from "../../comps/textfield/TextField";

import astra from "../../comps/assets/valorant_headshots/astra-concept-headshot.png";
import breach from "../../comps/assets/valorant_headshots/breach-concept-headshot.png";
import brimstone from "../../comps/assets/valorant_headshots/brimstone-concept-headshot.png";
import cypher from "../../comps/assets/valorant_headshots/cypher-concept-headshot.png";
import jett from "../../comps/assets/valorant_headshots/jett-concept-headshot.png";
import killjoy from "../../comps/assets/valorant_headshots/killjoy-concept-headshot.png";
import omen from "../../comps/assets/valorant_headshots/omen-concept-headshot.png";
import phoenix from "../../comps/assets/valorant_headshots/phoenix-concept-headshot.png";
import raze from "../../comps/assets/valorant_headshots/raze-concept-headshot.png";
import reyna from "../../comps/assets/valorant_headshots/reyna-concept-headshot.png";
import sage from "../../comps/assets/valorant_headshots/sage-concept-headshot.png";
import skye from "../../comps/assets/valorant_headshots/skye-concept-headshot.png";
import sova from "../../comps/assets/valorant_headshots/sova-concept-headshot.png";
import viper from "../../comps/assets/valorant_headshots/viper-concept-headshot.png";
import yoru from "../../comps/assets/valorant_headshots/yoru-concept-headshot.png";
import KAYO from "../../comps/assets/valorant_headshots/KAYO_icon.png";
import ChampionList from "../../config/wr/champions.json";
import { ChampionProps, Skin } from "../../config/types/wr_champion.interface";

const agents = {
  astra: astra,
  breach: breach,
  brimstone: brimstone,
  cypher: cypher,
  jett: jett,
  killjoy: killjoy,
  omen: omen,
  phoenix: phoenix,
  raze: raze,
  reyna: reyna,
  sage: sage,
  skye: skye,
  sova: sova,
  viper: viper,
  yoru: yoru,
  kayo: KAYO,
};

const mcs = makeStyles((theme) => ({
  select: {
    maxHeight: 300,
    // width: 200,
  },
  listsubheader: {
    backgroundColor: "#9c27b0",
    color: "#fff",
  },
  player: {
    marginTop: 15,
    display: "flex",
    alignItems: "center",
    "& .logo": {
      height: 85,
      width: 85,
      border: "1px solid rgba(0,0,0,.1)",
      borderRadius: 3,
      marginRight: 20,
      backgroundSize: "contain",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
    },
    "& .info": {
      "& .ign": {
        fontWeight: "bold",
        fontSize: "1.2rem",
      },
      "& .name": {
        fontSize: "1rem",
      },
    },
  },
  headWrapper: {
    display: "flex",
    alignItems: "center",
    "& h5": {
      flex: 1,
    },
  },
  fabWrapper: {
    margin: theme.spacing(1),
    position: "relative",
  },
  fabProgress: {
    color: green[500],
    position: "absolute",
    top: -6,
    left: -6,
    zIndex: 1,
  },
  statAdd: {
    marginTop: 40,
    display: "flex",
    "& .input": {
      marginRight: theme.spacing(2),
    },
  },
  stats: {
    display: "flex",
    flexDirection: "column",
    "& .stat": {
      display: "flex",
      alignItems: "center",
      "& > *": {
        marginRight: 10,
      },
    },
  },
  agentMenu: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr",
  },
  adjustments: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: 20,
    "& > *": {
      marginRight: 10,
    },
  },
}));

const LogitechMVP = () => {
  const classes = mcs();
  const { tournament, logitech_mvp } = useSelector(
    (state: ReduxState) => state.live
  );

  const ws = React.useContext(wsContext);
  const [anchorEl, setAnchorEl] = React.useState<any>(null);
  const [form, setForm] = React.useState<LogitechMVPProps>({
    player_adjustments: {
      x: 0,
      y: 0,
      scale: 0,
    },
    character_adjustments: {
      x: 0,
      y: 0,
      scale: 0,
    },
    name: "",
    ign: "",
    champion: "",
    championName: "",
    championIcon: "",
    agent: "jett",
    stats: [
      {
        stat_name: "Kills",
        stat_value: "0",
        isOn: true,
      },
      {
        stat_name: "Deaths",
        stat_value: "0",
        isOn: true,
      },
      {
        stat_name: "Assists",
        stat_value: "0",
        isOn: true,
      },
    ],
  });
  const [agentAnchor, setAgentAnchor] = React.useState<any>(null);

  React.useEffect(() => {
    if (logitech_mvp) {
      setForm(logitech_mvp);
    }
  }, [logitech_mvp, setForm]);

  // React.useEffect(() => {
  //   if (form) {
  //     console.log(form);
  //   }
  // }, [form]);

  const save = () => {
    ws.setLiveSettings({ logitech_mvp: form } as Partial<Live>);
  };

  const handleStatChange =
    (s: Stat, i: number) =>
    ({
      currentTarget: { name, value },
    }: React.ChangeEvent<HTMLInputElement>) => {
      setForm({
        ...form,
        stats: form.stats.map((stat, ii) =>
          stat === s && ii === i ? { ...stat, [name]: value } : stat
        ),
      });
    };

  const agentSelect = (agent: LogitechMVPProps["agent"]) => () => {
    setForm({ ...form, agent });
    setAgentAnchor(null);
  };

  return (
    <Sheet>
      <SheetHead
        color="red"
        icon={
          <SvgIcon fontSize="large">
            <LogitechLogo />
          </SvgIcon>
        }
      >
        <div className={classes.headWrapper}>
          <div style={{ flex: 1 }}>
            <SheetHeadTitle>Loditech MVP</SheetHeadTitle>
            <SheetHeadSub>
              goes to{" "}
              <a
                href="http://aa-uac.web.app/logitechMVP"
                style={{ color: "#fff", textDecoration: "none" }}
              >
                /logitechMVP
              </a>
            </SheetHeadSub>
          </div>
          <div className={classes.fabWrapper}>
            <Fab
              color="primary"
              onClick={save}
              disabled={logitech_mvp === form}
            >
              {logitech_mvp === form ? (
                <CheckIcon fontSize="large" />
              ) : (
                <SaveOutlinedIcon fontSize="large" />
              )}
            </Fab>
          </div>
        </div>
      </SheetHead>
      <SheetBody>
        <Button
          variant="contained"
          color="primary"
          onClick={({ currentTarget }) => setAnchorEl(currentTarget)}
        >
          Select Player
        </Button>

        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
          classes={{ paper: classes.select }}
        >
          {SelectionItems(
            tournament?.participants ?? [],
            form,
            setForm,
            ws,
            () => setAnchorEl(null)
          ).map((item: any) => item)}
        </Menu>

        <Button
          variant="contained"
          color="secondary"
          style={{
            marginLeft: 20,
          }}
          onClick={({ currentTarget }) => setAgentAnchor(currentTarget)}
          startIcon={
            <Avatar
              src={
                tournament?.game_name === "League of Legends: Wild Rift"
                  ? form.championIcon || ChampionList[0].skins[0].icon.url
                  : agents[form?.agent ?? "jett"]
              }
            />
          }
        >
          {tournament?.game_name === "League of Legends: Wild Rift"
            ? form.championName || ChampionList[0].title
            : form.agent}
        </Button>

        {tournament?.game_name === "League of Legends: Wild Rift" ? (
          <WildRiftChampions
            anchorEl={agentAnchor}
            open={Boolean(agentAnchor)}
            onClose={() => setAgentAnchor(null)}
            // className={classes.agentMenu}
            setForm={setForm}
            classes={{ list: classes.agentMenu }}
          />
        ) : (
          <Menu
            anchorEl={agentAnchor}
            open={Boolean(agentAnchor)}
            onClose={() => setAgentAnchor(null)}
            // className={classes.agentMenu}
            classes={{ list: classes.agentMenu }}
          >
            {Object.keys(agents).map((agent) => (
              <MenuItem
                key={agent}
                onClick={agentSelect(agent as LogitechMVPProps["agent"])}
              >
                <ListItemAvatar>
                  <Avatar
                    src={agents[(agent as LogitechMVPProps["agent"]) ?? "jett"]}
                  />
                </ListItemAvatar>
                <ListItemText
                  primary={agent}
                  style={{ textTransform: "capitalize" }}
                ></ListItemText>
              </MenuItem>
            ))}
          </Menu>
        )}

        <SheetSection className={classes.player}>
          <div
            className="logo"
            style={{ backgroundImage: `url("${form.team?.logo}")` }}
          ></div>
          <div
            className="logo"
            style={{ backgroundImage: `url("${form.photo_main}")` }}
          ></div>
          <div className="info">
            <div className="ign">{form.ign}</div>
            <div className="name">{form.name}</div>
          </div>
        </SheetSection>

        <SheetSection>
          <Typography variant="h4">Adjustments</Typography>

          <div
            style={{ display: "flex", justifyContent: "center", width: "100%" }}
          >
            <div
              className={classes.adjustments}
              style={{
                borderRight: "1px solid rgba(255,255,255,.3)",
              }}
            >
              <Typography variant="h4" style={{ alignSelf: "center" }}>
                Player
              </Typography>
              <TextField
                label="Horizontal Offset (px)"
                value={form.player_adjustments.x}
                size="small"
                type="number"
                inputProps={{
                  step: 5,
                }}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setForm({
                    ...form,
                    player_adjustments: {
                      ...form.player_adjustments,
                      x: e.target.valueAsNumber,
                    },
                  })
                }
              />

              <TextField
                label="Size Multiplyer (%)"
                value={form.player_adjustments.scale}
                size="small"
                type="number"
                inputProps={{ step: 1 }}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setForm({
                    ...form,
                    player_adjustments: {
                      ...form.player_adjustments,
                      scale: e.target.valueAsNumber,
                    },
                  })
                }
              />
            </div>
            <div className={classes.adjustments}>
              <Typography variant="h4" style={{ alignSelf: "center" }}>
                Agent | Champion
              </Typography>

              <TextField
                label="Horizontal Offset (px)"
                value={form.character_adjustments?.x}
                size="small"
                type="number"
                inputProps={{ step: 5 }}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setForm({
                    ...form,
                    character_adjustments: {
                      ...form.character_adjustments,
                      x: e.target.valueAsNumber,
                    },
                  })
                }
              />
              <TextField
                label="Vertical Offset (px)"
                value={form.character_adjustments.y}
                size="small"
                type="number"
                inputProps={{ step: 5 }}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setForm({
                    ...form,
                    character_adjustments: {
                      ...form.character_adjustments,
                      y: e.target.valueAsNumber,
                    },
                  })
                }
              />
              <TextField
                label="Size Multiplyer (%)"
                value={form.character_adjustments.scale}
                size="small"
                type="number"
                inputProps={{ step: 1 }}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setForm({
                    ...form,
                    character_adjustments: {
                      ...form.character_adjustments,
                      scale: e.target.valueAsNumber,
                    },
                  })
                }
              />
              <FormControlLabel
                label="Flip X"
                control={
                  <Switch
                    checked={form.character_adjustments.flip_x}
                    onChange={(e) =>
                      setForm({
                        ...form,
                        character_adjustments: {
                          ...form.player_adjustments,
                          flip_x: e.target.checked,
                        },
                      })
                    }
                  />
                }
              />
            </div>
          </div>
        </SheetSection>

        <SheetSection>
          <Typography variant="h4">Stats</Typography>
          <div className={classes.stats}>
            {form.stats?.map((stat, i) => (
              <div className="stat" key={i}>
                <TextField
                  value={stat.stat_name}
                  name="stat_name"
                  size="small"
                  disabled
                  onChange={handleStatChange(stat, i)}
                />
                <TextField
                  value={stat.stat_value}
                  name="stat_value"
                  size="small"
                  onChange={handleStatChange(stat, i)}
                />
              </div>
            ))}
          </div>
        </SheetSection>
      </SheetBody>
      <SheetFooter>
        This is different from the player displayed in lower thirds
      </SheetFooter>
    </Sheet>
  );
};

interface WRChampMenuProps extends MenuProps {
  setForm: Dispatch<SetStateAction<LogitechMVPProps>>;
  onClose: () => void;
}

const WildRiftChampions: VFC<WRChampMenuProps> = (props) => {
  const [filter, setFilter] = useState("");
  const [state, setState] = useState<Skin[]>([]);
  const [currentChampion, setCurrentChampion] = useState("");

  const selectItem =
    ({ url, icon }: { url: string; icon: string }) =>
    () => {
      props.setForm((f) => ({
        ...f,
        champion: url,
        championName: currentChampion,
        championIcon: icon,
      }));
      setState([]);
      props.onClose();
    };
  return (
    <Menu {...props}>
      <MenuItem
        onKeyDown={(e) => {
          e.stopPropagation();
        }}
      >
        <TextField
          label="search"
          value={filter}
          onChange={(e) => setFilter(e.target.value)}
        />
      </MenuItem>
      {(ChampionList as ChampionProps[])
        .filter((c) => c.title.includes(filter.toUpperCase()))
        .map((champion) => (
          <MenuItem
            key={champion.title}
            onClick={() => {
              setState(champion.skins);
              setCurrentChampion(champion.title);
            }}
          >
            <ListItemAvatar>
              <Avatar src={champion.skins[0].icon.url} />
            </ListItemAvatar>
            <ListItemText
              primary={champion.title}
              style={{ textTransform: "capitalize" }}
            ></ListItemText>
          </MenuItem>
        ))}
      {/*  */}

      <Dialog
        maxWidth="md"
        fullWidth
        open={Boolean(state.length)}
        onClose={() => setState([])}
      >
        <DialogTitle>Select Skin</DialogTitle>
        <DialogContent
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr 1fr",
            gap: 10,
          }}
        >
          {state.map((skin, i) => (
            <Button
              key={i}
              style={{
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundImage: `url("${skin.splash.url}")`,
                minHeight: 200,
              }}
              onClick={selectItem({
                url: skin.splash.url,
                icon: skin.icon.url,
              })}
            ></Button>
          ))}
        </DialogContent>
      </Dialog>
    </Menu>
  );
};

const SelectionItems = (
  participants: Participant[],
  form: LogitechMVPProps,
  setForm: React.Dispatch<React.SetStateAction<LogitechMVPProps>>,
  ws: WebsocketProps,
  onClose: () => void
) => {
  const classes = mcs();
  let list: any[] = [];
  participants
    .filter((team) => Boolean(team?.players?.length))
    .forEach((team) => {
      list = [
        ...list,
        <ListSubheader key={team.id} className={classes.listsubheader}>
          {team.display_name}
        </ListSubheader>,
      ];

      team.players?.forEach((p) => {
        list = [
          ...list,
          <MenuItem
            button
            dense
            key={p.ign}
            onClick={() => {
              setForm({ ...form, ...p, team });
              onClose();
            }}
          >
            <ListItemAvatar>
              <Avatar src={p.photo_main} />
            </ListItemAvatar>
            <ListItemText>{p.ign}</ListItemText>
          </MenuItem>,
        ];
      });
    });
  return list;
};

export default LogitechMVP;
