import useSettings from "@lib/useSettings";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  FormControlLabel,
  Grid,
  Link,
  makeStyles,
  Switch,
  TextField,
  Typography,
} from "@material-ui/core";
import blobber from "@utils/blobber";
import DeviceSignalCellularConnectedNoInternet0Bar from "material-ui/svg-icons/device/signal-cellular-connected-no-internet-0-bar";
import React, { ChangeEvent, FC, useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import swal from "sweetalert";
import fbase, {
  projectFirestore as db,
  projectStorage,
} from "../../config/firebase/config";
import {
  adjustmentDefaultValues,
  AdjustmentsProps,
} from "../../config/types/adjustments.interface";
import {
  AdProps,
  AdPropsImg,
  ImageAdjustmentProps,
} from "../../config/types/ads.interface";
import { Live, ReduxState } from "../../config/types/types";
import { UploadProps } from "../../config/types/upload.interface";
import { wsContext } from "../../config/websocket/WebsocketProvider";

const mcs = makeStyles((theme) => ({
  content: {},
}));

interface AdDialogProps extends DialogProps {
  docSnap: fbase.firestore.DocumentSnapshot | null;
}

const defaultFormValues: AdProps = {
  content: {
    text: "",
    adj: adjustmentDefaultValues,
  },
  head: {
    text: "",
    adj: adjustmentDefaultValues,
  },
  img: {
    url: "",
    adj: {
      ...adjustmentDefaultValues,
      h: 0,
      w: 0,
    },
  },
};

const fileTypes = [
  "image/png",
  "image/jpeg",
  "image/gif",
  "image/svg",
  "image/svg+xml",
];

const AdDialog: FC<AdDialogProps> = ({
  open,
  onClose = () => {},
  docSnap,
  ...props
}) => {
  const classes = mcs();
  const [ad, setAd] = useState<AdProps>(defaultFormValues);
  const [loading, setloading] = useState<boolean>(false);
  const [imgRef, setImgRef] = useState<string>("");
  const ws = useContext(wsContext);
  const { preloadImages } = useSettings();

  const { tournament, lowerThirds, lowerThirdsIngame, room } = useSelector(
    (state: ReduxState) => state.live
  );

  let docRef = docSnap?.ref;

  useEffect(() => {
    if (!docSnap) {
      setAd(defaultFormValues);
    } else {
      setAd(docSnap?.data() as AdProps);
    }
  }, [docSnap]);

  const handleChange =
    (category: keyof AdProps) => (e: ChangeEvent<HTMLInputElement>) => {
      setAd({
        ...ad,
        [category]: { ...ad[category], [e.target.name]: e.target.value },
      });
      console.log(ad);
    };

  const fileSelect =
    (destination: string = "Ads/") =>
    ({ target: { files } }: ChangeEvent<HTMLInputElement>) => {
      const image = files?.length ? files[0] : null;
      if (image) {
        uploadFile({ file: image, destination });
      }
    };

  const uploadFile = ({ file, destination }: UploadProps) => {
    const storageRef = projectStorage.ref(destination + file.name);

    storageRef.put(file).on(
      "state_change",

      (snap) => {
        setloading(true);
      },

      (err) => {
        setloading(false);
        swal({
          title: err.code,
          text: err.message,
          icon: "error",
          timer: 5000,
        });
      },

      async () => {
        const url = await storageRef.getDownloadURL();
        setAd({ ...ad, img: { ...ad?.img, url } });
        setloading(false);
      }
    );
  };

  const navigate = (e: React.SyntheticEvent) => {
    e.preventDefault();
    window.open(ad?.img?.url, "_blank");
  };

  const setAdj =
    (prop: keyof AdProps) => (e: ChangeEvent<HTMLInputElement>) => {
      console.log(e.target.valueAsNumber);
      setAd({
        ...ad,
        [prop]: {
          ...ad[prop],
          adj: {
            ...ad[prop]?.adj,
            [e.target.name]: e.target.valueAsNumber,
          },
        },
      });
    };

  const flipX = ({ target: { checked } }: ChangeEvent<HTMLInputElement>) => {
    setAd({
      ...ad,
      img: {
        ...(ad?.img ?? { url: "" }),
        adj: {
          ...(ad?.img?.adj as ImageAdjustmentProps),
          flip_x: checked,
        },
      },
    });
  };

  const applyChanges = async () => {
    let newAd = ad;
    if (preloadImages) {
      newAd = {
        ...newAd,
        img: {
          ...(newAd.img as AdPropsImg),
          url_base64: (await blobber(newAd.img?.url ?? "")) ?? "",
        },
      };
    }
    ws.setLiveSettings({
      lowerThirds: { ...lowerThirds, ad: newAd },
      lowerThirdsIngame: { ...lowerThirdsIngame, ad: newAd },
    } as Partial<Live>);
  };

  const save = () => {
    if (docRef) {
      docRef.set(ad).then(() => {
        swal({
          title: "Successfully Updated Ad!",
          icon: "success",
          timer: 3000,
        }).then(() => {
          close();
        });

        applyChanges();
      });
    } else {
      db.collection("tournaments")
        .doc(tournament?.url ?? "")
        .collection("ads")
        .add(ad)
        .then(() => {
          swal({
            title: "Successfully Updated Ad!",
            icon: "success",
            timer: 3000,
          }).then(() => {
            close();
          });
        });
    }
  };

  const cancel = () => {
    swal({
      title: "Cancel Ad?",
      text: "All changes will be lost.",
      icon: "warning",
      dangerMode: true,
      buttons: ["Run it back!", true],
    }).then((value) => {
      if (value) {
        close();
      }
    });
  };

  const close = () => {
    setAd(defaultFormValues);
    onClose({}, "escapeKeyDown");
  };

  const deleteAd = () => {
    docRef?.delete().then(() => {
      swal({
        title: "Successfully Deleted the Ad",
        icon: "success",
        timer: 5000,
      }).then(() => {
        close();
      });
    });
  };
  const resetAdjustments = () => {
    setAd({
      content: { text: ad.content.text, adj: adjustmentDefaultValues },
      head: { text: ad.head?.text ?? "", adj: adjustmentDefaultValues },
      img: {
        url: ad.img?.url ?? "",
        adj: {
          ...adjustmentDefaultValues,
          h: 0,
          w: 0,
        },
      },
    });
  };

  return (
    <Dialog open={open} onClose={onClose} classes={{ paper: classes.content }}>
      <DialogTitle>{docSnap ? `Edit ${docSnap.id}` : "Create Ad"}</DialogTitle>
      <DialogContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              fullWidth
              label="Head"
              value={ad?.head?.text}
              name="text"
              onChange={handleChange("head")}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              fullWidth
              label="Content"
              value={ad?.content?.text}
              name="text"
              multiline
              rows={2}
              rowsMax={3}
              onChange={handleChange("content")}
            />
          </Grid>
          <Grid item xs={12}>
            <Button component="label">
              <input
                type="file"
                hidden
                accept={fileTypes.join(", ")}
                onChange={fileSelect("Ads/")}
              />
              Add Image
            </Button>

            {loading && (
              <Typography style={{ paddingLeft: 10 }} variant="caption">
                Uploading...
              </Typography>
            )}

            {ad?.img?.url && (
              <Link
                href={ad?.img?.url}
                target="_blank"
                variant="caption"
                style={{ paddingLeft: 10 }}
                onClick={navigate}
              >
                Click here to preview
              </Link>
            )}
          </Grid>

          <Grid item xs={4}>
            <TextField
              variant="outlined"
              fullWidth
              label="Head font size (px)"
              value={ad?.head?.adj?.font_size}
              name="font_size"
              size="small"
              type="number"
              inputProps={{ step: 1 }}
              onChange={setAdj("head")}
              helperText="Set to 0 for default"
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              variant="outlined"
              fullWidth
              label="Content font size (px)"
              value={ad?.content?.adj?.font_size}
              name="font_size"
              size="small"
              type="number"
              inputProps={{ step: 1 }}
              onChange={setAdj("content")}
              helperText="Set to 0 for default"
            />
          </Grid>

          <Grid item xs={4} />

          <Grid item xs={4}>
            <TextField
              variant="outlined"
              fullWidth
              label="Image height (px)"
              value={ad?.img?.adj?.h}
              name="h"
              size="small"
              type="number"
              inputProps={{ step: 1 }}
              onChange={setAdj("img")}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              variant="outlined"
              fullWidth
              label="Image width (px)"
              value={ad?.img?.adj?.w}
              name="w"
              size="small"
              type="number"
              inputProps={{ step: 1 }}
              onChange={setAdj("img")}
            />
          </Grid>
          <Grid item xs={4}>
            <FormControlLabel
              control={
                <Switch checked={ad?.img?.adj?.flip_x} onChange={flipX} />
              }
              label="Flip X"
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              variant="outlined"
              fullWidth
              label="Image X offset (px)"
              value={ad?.img?.adj?.x}
              name="x"
              size="small"
              type="number"
              inputProps={{ step: 5 }}
              onChange={setAdj("img")}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              variant="outlined"
              fullWidth
              label="Image Y offset (px)"
              value={ad?.img?.adj?.y}
              name="y"
              size="small"
              type="number"
              inputProps={{ step: 5 }}
              onChange={setAdj("img")}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              variant="outlined"
              fullWidth
              label="Image Scale Multiplyer"
              value={ad?.img?.adj?.scale}
              name="scale"
              size="small"
              type="number"
              inputProps={{ step: 1 }}
              onChange={setAdj("img")}
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        {docRef && (
          <Button variant="outlined" color="secondary" onClick={deleteAd}>
            Delete
          </Button>
        )}
        <Button variant="outlined" onClick={cancel}>
          Cancel
        </Button>
        <Button variant="outlined" color="primary" onClick={resetAdjustments}>
          Reset Adjustments
        </Button>
        <Button
          variant="contained"
          color="primary"
          disabled={!ad?.head?.text && !ad?.content.text}
          onClick={applyChanges}
        >
          Apply
        </Button>
        <Button
          variant="contained"
          color="primary"
          disabled={!ad?.head?.text && !ad?.content.text}
          onClick={save}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AdDialog;
