import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@material-ui/core";
import React, { Dispatch, FC, SetStateAction, useState } from "react";
import { DialogProps } from "../../config/types/dialogsTypes";
import { Participant, Player } from "../../config/types/types";

interface AddBulkPlayerProps extends DialogProps {
  setParticipant: Dispatch<SetStateAction<Participant>>;
}

const ParticipantPlayersAddBulk: FC<AddBulkPlayerProps> = ({
  open,
  onClose = () => {},
  setParticipant,
}) => {
  const [form, setForm] = useState("");

  const add = () => {
    let rows: Player[] = form.split("\n").map((r) => {
      let [ign, name] = r.split("\t");
      return { ign, name };
    });

    setParticipant((p) => ({
      ...p,
      players: [...(p.players ?? []), ...rows],
    }));
    setForm("");
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Add Bulk Players</DialogTitle>
      <DialogContent>
        <TextField
          value={form}
          onChange={(e) => setForm(e.target.value)}
          variant="outlined"
          multiline
          rows={3}
          rowsMax={6}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={add}>Add</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ParticipantPlayersAddBulk;
