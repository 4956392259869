import axios from "axios";
import { cors } from "./cors";

const username = "manoku";
const password = "fxlXW8fEub2OQ2FIMVKkpw4MVs80aKlZ1PYcruxn";

export const challongeApi = axios.create({
  baseURL: cors("https://api.challonge.com/v1"),
  auth: { username, password },
  params: {
    include_participants: 1,
    include_matches: 1,
  },
  headers: {
    "X-Requested-With": "AcadArena/1.0",
  },
});
