import useSettings from "@lib/useSettings";
import axios from "axios";
import toast from "react-hot-toast";
import addCors from "./addCors";
import { useAsync } from "./asyncHook";
import toBase64 from "./toBase64";

export default async function (url: string, disableToast?: boolean) {
  const promise = axios.get<Blob>(addCors(url), {
    responseType: "blob",
    headers: { "x-reqequested-with": "AcadArena/1.0" },
  });
  if (!disableToast) {
    toast.promise(promise, {
      loading: "Preloading Images...",
      success: "Success",
      error: (err) => err.message,
    });
  }
  const [blob, err] = await useAsync(promise);
  if (blob) {
    const [base64, baseErr] = await useAsync(toBase64(blob.data));
    return base64;
  } else {
    return "";
  }
}
