import firebase, { projectAuth } from "@config/firebase/config";
import { Button, Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import { useAuthState } from "react-firebase-hooks/auth";

const AuthDialog = () => {
  const [user] = useAuthState(projectAuth);

  function signOut() {
    projectAuth.signOut();
  }
  const signIn = () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    projectAuth.signInWithPopup(provider);
  };

  const regex = /.*@acadarena\.com/;
  const isStaff = regex.test(user?.email);
  return (
    <Dialog open={!isStaff}>
      <DialogTitle>Who are you?</DialogTitle>
      <DialogContent>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {user ? (
            !isStaff ? (
              <>
                <div>YOU ARE NOT ALLOWED TO USE THIS APP</div>
                <Button variant="contained" onClick={signOut}>
                  Sign Out
                </Button>
              </>
            ) : (
              ""
            )
          ) : (
            <Button variant="contained" onClick={signIn}>
              Sign in with Google
            </Button>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default AuthDialog;
