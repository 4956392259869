import React, { FC, useContext, useEffect, useState } from "react";
import Sheet from "../../comps/sheet/Sheet";
import SheetHead from "../../comps/sheet/SheetHead";
import PauseIcon from "@material-ui/icons/Pause";
import SheetHeadTitle from "../../comps/sheet/SheetHeadTitle";
import SheetBody from "../../comps/sheet/SheetBody";
import { Avatar, Button, ButtonGroup } from "@material-ui/core";
import { useSelector } from "react-redux";
import { Live, Participant, ReduxState } from "../../config/types/types";
import { PauseType } from "../../config/types/pause.interface";
import { wsContext } from "../../config/websocket/WebsocketProvider";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";

const Pause: FC = () => {
  const { pause, tournament, match } = useSelector(
    (state: ReduxState) => state.live
  );
  const ws = useContext(wsContext);
  const [team1, setTeam1] = useState<Participant | undefined>();
  const [team2, setTeam2] = useState<Participant | undefined>();

  /**
   * Required to fill team1 and team2 with useEffect
   * @param id number
   * @returns Participant
   */
  const team = (id: number = 0) => {
    return tournament?.participants?.find(
      (p) => p.group_player_ids.includes(id) || p.id === id
    );
  };

  useEffect(() => {
    if (!match && !tournament) return;
    setTeam1(team(match?.player1_id ?? 0));
    setTeam2(team(match?.player2_id ?? 0));
  }, [match, tournament]);

  const goPause = (type: PauseType, team?: Participant) => () => {
    ws.setLiveSettings({
      pause: { paused: true, team, type },
    } as Partial<Live>);
  };

  const unPause = () => {
    ws.setLiveSettings({ pause: { ...pause, paused: false } } as Partial<Live>);
  };

  return (
    <Sheet style={{ height: "auto" }}>
      <SheetHead icon={<PauseIcon />}>
        <SheetHeadTitle>Pause Section</SheetHeadTitle>
      </SheetHead>

      <SheetBody>
        <ButtonGroup fullWidth variant="contained">
          <Button
            startIcon={<Avatar src={team1?.logo} />}
            onClick={goPause(PauseType.tactical, team1)}
          >
            {team1?.university_acronym} Tactical
          </Button>
          <Button onClick={goPause(PauseType.technical)}>Technical</Button>
          <Button
            endIcon={<Avatar src={team2?.logo} />}
            onClick={goPause(PauseType.tactical, team2)}
          >
            {team2?.university_acronym} Tactical
          </Button>
        </ButtonGroup>
        <Button
          fullWidth
          startIcon={<PlayArrowIcon />}
          onClick={unPause}
          disabled={!pause?.paused}
          style={{ marginTop: 10 }}
          variant="outlined"
        >
          Unpause
        </Button>
      </SheetBody>
    </Sheet>
  );
};

export default Pause;
