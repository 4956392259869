export async function useAsync<ErrorType = any, DataType = any>(
  promise: Promise<DataType>
): Promise<[DataType | undefined, ErrorType | undefined]> {
  try {
    const data = await promise;
    return [data, undefined];
  } catch (err) {
    return [undefined, err];
  }
}
