import React, { createContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setLiveSettings,
  setWebsocketState,
  setWebsocketUsers,
} from "../redux/Actions";
import io from "socket.io-client";
import { Live, ReduxState, WebsocketUser } from "../types/types";
import { store } from "../..";

export interface WebsocketProps {
  socket?: any;
  setLiveSettings: (settings: Partial<Live>) => void;
  joinRoom: (room: string, user?: string) => void;
}

export const wsContext = createContext<WebsocketProps>({
  setLiveSettings: () => {},
  joinRoom: () => {},
});

let config = {
  host: "",
  path: "",
};
const hostCloud: string = "https://servers.acadarena.com/";
const hostLocal: string = `${window.location.hostname}:3200`;

if (process.env.NODE_ENV === "development") {
  config = {
    host: hostLocal,
    path: "/socket.io",
  };
} else {
  config = {
    host: hostCloud,
    path: "/rot-ws/socket.io",
  };
}

let socket: any = io
  .connect(`${config.host}`, {
    path: config.path,
    transports: ["websocket", "polling", "flashsocket"],
  })
  .on("connect", () => {
    store.dispatch(setWebsocketState(true));
  })
  .on("set_live_settings", (settings: Partial<Live>) =>
    store.dispatch(setLiveSettings(settings))
  )
  .on("usersUpdate", (users: WebsocketUser[]) =>
    store.dispatch(setWebsocketUsers(users))
  )
  .on("disconnect", () => {
    store.dispatch(setWebsocketState(false));
  })
  .on("connection_error", () => {
    store.dispatch(setWebsocketState(false));
  });

const WebsocketProvider: React.FC = ({ children }) => {
  const dispatch = useDispatch();
  let ws: WebsocketProps = {
    setLiveSettings: () => {},
    joinRoom: () => {},
  };
  const { room: ClientRoom } = useSelector((state: ReduxState) => state.live);
  ws = {
    socket,
    setLiveSettings: (settings: Partial<Live>) =>
      socket.emit("set_live_settings", { data: settings, room: ClientRoom }),
    joinRoom: (room, user?) => {
      dispatch(setLiveSettings({ room }));
      socket.emit("join_room", { room, username: user ?? "controller" });
    },
  };
  return <wsContext.Provider value={ws}>{children}</wsContext.Provider>;
};

export default WebsocketProvider;
