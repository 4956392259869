import {
  Button,
  Dialog,
  Grid,
  makeStyles,
  Typography,
  FormControlLabel,
  Switch,
  ButtonGroup,
  IconButton,
} from "@material-ui/core";
import React from "react";
import { useSelector } from "react-redux";
import Sheet from "../sheet/Sheet";
import SheetBody from "../sheet/SheetBody";
import SheetHead from "../sheet/SheetHead";
import SheetHeadSub from "../sheet/SheetHeadSub";
import SheetHeadTitle from "../sheet/SheetHeadTitle";
import {
  Match,
  Participant,
  ReduxState,
  Tournament,
} from "../../config/types/types";
import { wsContext } from "../../config/websocket/WebsocketProvider";
import axios from "axios";
import { projectFirestore } from "../../config/firebase/config";
import InfoIcon from "@material-ui/icons/Info";
import swal from "sweetalert";
import SheetSection from "../sheet/SheetSection";
import TextField from "../textfield/TextField";
import ControlMatchPopupVeto from "./vetos/MatchVetoValorant";
import {
  KeyboardDateTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import MatchPickEm from "./MatchPickEm";
import MatchVetoCodm from "./vetos/MatchVetoCodm";
import { challongeApi } from "../../utils/challonge";
import useTournament from "@lib/useTournament";
import { Delete } from "@material-ui/icons";
import { isValid, parseISO } from "date-fns";
import toast from "react-hot-toast";
import blobber from "@utils/blobber";
import useSettings from "@lib/useSettings";
interface ControlMatchPopupProps {
  open: boolean;
  match?: Match;
  onClose: () => void;
  title: string;
}

const ms = makeStyles((theme) => ({
  dialog: {
    boxShadow: "none",
    backgroundColor: "transparent",
  },
  content: {
    display: "flex",
    flexDirection: "column",

    "& > *": {
      marginBottom: theme.spacing(2),
    },
  },

  scores: {
    marginTop: theme.spacing(2),
    "& > *": {
      margin: "5px 5px",
    },
  },
}));

/**
 * preload logos upon match selection
 * @param match
 * @param participants
 * @returns
 */
export function preloadMatchParticipants(
  match?: Match,
  participants: Participant[] = []
) {
  // const toastId = toast.loading("Preloading...");
  // let arr: Participant[] = [];
  const promise = Promise.all(
    participants.map(async (p) =>
      p.id === match?.player1_id ||
      p.id === match?.player2_id ||
      p.group_player_ids.includes(match?.player1_id ?? 0) ||
      p.group_player_ids.includes(match?.player2_id ?? 0)
        ? {
            ...p,
            logo_base64: await blobber(p.logo ?? "", true),
            university_banner_base64: await blobber(
              p.university_banner ?? "",
              true
            ),
          }
        : p
    )
  );

  toast.promise(promise, {
    loading: "Preloading images",
    error: (err) => err.message,
    success: "Done!",
  });

  return promise;
}

const ControlMatchPopup: React.FC<ControlMatchPopupProps> = ({
  open,
  match,
  onClose,
  title,
}) => {
  const c = ms();
  const {
    tournament,
    matches_today,
    match: matchWS,
  } = useSelector((state: ReduxState) => state.live);
  const ws = React.useContext(wsContext);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [scores, setScores] = React.useState<string>("0-0");
  const [vetoState, setVetoState] = React.useState<boolean>(false);
  const [pollState, setPollState] = React.useState<boolean>(false);
  const [badge, setBadge] = React.useState<string>("");
  const [bestOf, setBestOf] = React.useState<number>(1);
  const [scheduleDate, setScheduleDate] = React.useState<Date>(new Date());
  const [winner, setWinner] = React.useState<number | null>(
    match?.winner_id ?? null
  );

  const { team } = useTournament();
  const { preloadImages } = useSettings();

  React.useEffect(() => {
    if (!match) return;
    setScores(match?.scores_csv || "0-0");
    setBadge(match?.badge ?? "");
    setScheduleDate(
      !!match?.schedule && isValid(parseISO(match?.schedule + ""))
        ? match?.schedule
        : new Date()
    );
    setWinner(match.winner_id);
  }, [match, setScores]);

  const selectMatch = async () => {
    const data = {
      match: match,
      tournament: {
        ...(tournament as Tournament),
        participants: preloadImages
          ? await preloadMatchParticipants(match, tournament?.participants)
          : tournament?.participants ?? [],
      },
    };
    ws.setLiveSettings(data);
    onClose();
  };

  const refresh = () => {
    setLoading(true);
    challongeApi
      .get(`/tournaments/${tournament?.id}/matches/${match?.id}.json`)
      .then(({ data: { match: matchAxios } }: { data: { match: Match } }) => {
        setLoading(false);
        projectFirestore
          .collection("tournaments")
          .doc(tournament?.url)
          .set({
            ...tournament,
            matches: tournament?.matches?.map((m) =>
              m.id === match?.id
                ? {
                    ...m,
                    scores_csv: matchAxios.scores_csv || "0-0",
                    player1_prereq_match_id: matchAxios.player1_prereq_match_id,
                    player2_prereq_match_id: matchAxios.player2_prereq_match_id,
                    badge: badge,
                    schedule: scheduleDate,
                    winner_id: winner,
                  }
                : m
            ),
            participants: tournament?.participants?.map(
              ({ logo_base64, university_banner_base64, ...p }) => p
            ),
          })
          .then(() => {
            setScores(matchAxios.scores_csv);
            ws.setLiveSettings({
              tournament: {
                ...(tournament as Tournament),
                matches:
                  tournament?.matches?.map((m) =>
                    m.id === match?.id
                      ? {
                          ...m,
                          scores_csv: matchAxios.scores_csv || "0-0",
                          player1_prereq_match_id:
                            matchAxios.player1_prereq_match_id,
                          player2_prereq_match_id:
                            matchAxios.player2_prereq_match_id,
                          badge: badge,
                          schedule: scheduleDate,
                          winner_id: winner,
                        }
                      : m
                  ) ?? [],
              },
              matches_today:
                matches_today?.map((m) =>
                  m.id === matchAxios?.id
                    ? {
                        ...m,
                        scores_csv: matchAxios.scores_csv || "0-0",
                        player1_prereq_match_id:
                          matchAxios.player1_prereq_match_id,
                        player2_prereq_match_id:
                          matchAxios.player2_prereq_match_id,
                        badge: badge,
                        schedule: scheduleDate,
                        winner_id: winner,
                      }
                    : m
                ) ?? [],
              match:
                matchWS?.id === matchAxios.id
                  ? {
                      ...matchWS,
                      scores_csv: matchAxios.scores_csv || "0-0",
                      player1_prereq_match_id:
                        matchAxios.player1_prereq_match_id,
                      player2_prereq_match_id:
                        matchAxios.player2_prereq_match_id,
                      badge: badge,
                      schedule: scheduleDate,
                      winner_id: winner,
                    }
                  : matchWS,
            });
            onClose();
          });
      })
      .catch((err) => {
        setLoading(false);
        swal({
          title: "Something Went Wrong",
          text: err.message,
          icon: "error",
        });
      });
  };

  const handleChange =
    (playerIndex: number, scoreIndex: number) =>
    ({
      currentTarget: { valueAsNumber },
    }: React.ChangeEvent<HTMLInputElement>) => {
      let scoreArray = scores.split(",").map((s, i) => {
        if (i !== scoreIndex) return s;
        let ss = s.match(/^(\d*)-(\d*)/);
        let SSSarray = ss
          ?.map((sss, ii) => {
            if (ii !== playerIndex) return sss;
            return valueAsNumber || 0;
          })
          .filter((sss) => sss !== s);

        return SSSarray?.join("-");
      });
      setScores(scoreArray.join(","));
    };

  const apply = () => {
    setLoading(true);
    ws.setLiveSettings({
      tournament: {
        ...(tournament as Tournament),
        matches:
          tournament?.matches.map((m) =>
            m.id === match?.id
              ? {
                  ...m,
                  scores_csv: scores,
                  badge: badge,
                  schedule: scheduleDate,
                  bestOf: bestOf,
                  winner_id: winner,
                }
              : match?.id === m.player1_prereq_match_id
              ? {
                  ...m,
                  player1_id: winner,
                }
              : match?.id === m.player2_prereq_match_id
              ? {
                  ...m,
                  player2_id: winner,
                }
              : m
          ) ?? [],
      },
      matches_today:
        matches_today?.map((m) =>
          m.id === match?.id
            ? {
                ...m,
                scores_csv: scores,
                badge: badge,
                schedule: scheduleDate,
                bestOf: bestOf,
                winner_id: winner,
              }
            : match?.id === m.player1_prereq_match_id
            ? {
                ...m,
                player1_id: winner,
              }
            : match?.id === m.player2_prereq_match_id
            ? {
                ...m,
                player2_id: winner,
              }
            : m
        ) ?? [],
      match:
        matchWS?.id === match?.id
          ? {
              ...(matchWS as Match),
              scores_csv: scores,
              badge: badge,
              schedule: scheduleDate,
              bestOf: bestOf,
              winner_id: winner,
            }
          : (matchWS as Match),
    });
    setLoading(false);
    onClose();

    const promise = projectFirestore
      .collection("tournaments")
      .doc(tournament?.url)
      .set({
        ...tournament,
        matches: tournament?.matches.map((m) =>
          m.id === match?.id
            ? {
                ...m,
                scores_csv: scores,
                badge: badge,
                schedule: scheduleDate,
                bestOf: bestOf,
                winner_id: winner,
              }
            : m
        ),
        participants: tournament?.participants?.map(
          ({ logo_base64, university_banner_base64, ...p }) => p
        ),
      });

    toast.promise(promise, {
      loading: "Saving...",
      error: (err) => err.message,
      success: "Saved",
    });
    // .then(() => {

    // })
    // .catch((err) => {
    //   setLoading(false);
    //   ws.setLiveSettings({
    //     tournament: {
    //       ...(tournament as Tournament),
    //       matches:
    //         tournament?.matches.map((m) =>
    //           m.id === match?.id
    //             ? {
    //                 ...m,
    //                 scores_csv: scores,
    //                 badge: badge,
    //                 schedule: scheduleDate,
    //                 bestOf: bestOf,
    //                 winner_id: winner,
    //               }
    //             : match?.id === m.player1_prereq_match_id
    //             ? {
    //                 ...m,
    //                 player1_id: winner,
    //               }
    //             : match?.id === m.player2_prereq_match_id
    //             ? {
    //                 ...m,
    //                 player2_id: winner,
    //               }
    //             : m
    //         ) ?? [],
    //     },
    //     matches_today: matches_today?.map((m) =>
    //       m.id === match?.id
    //         ? {
    //             ...m,
    //             scores_csv: scores,
    //             badge: badge,
    //             schedule: scheduleDate,
    //             bestOf: bestOf,
    //             winner_id: winner,
    //           }
    //         : match?.id === m.player1_prereq_match_id
    //         ? {
    //             ...m,
    //             player1_id: winner,
    //           }
    //         : match?.id === m.player2_prereq_match_id
    //         ? {
    //             ...m,
    //             player2_id: winner,
    //           }
    //         : m
    //     ),
    //     match:
    //       matchWS?.id === match?.id
    //         ? {
    //             ...(matchWS as Match),
    //             scores_csv: scores,
    //             badge: badge,
    //             schedule: scheduleDate,
    //             bestOf: bestOf,
    //             winner_id: winner,
    //           }
    //         : (matchWS as Match),
    //   });

    //   swal({
    //     title: "Something went wrong",
    //     text: `${err.code}, could not save to database but still sent to websocket`,
    //     icon: "error",
    //   }).then(() => {
    //     onClose();
    //   });
    // });
  };

  const addMatch = () => {
    setScores(scores + ",0-0");
  };

  const handleDateChange = (date: Date | null) => {
    setScheduleDate(date || new Date());
  };

  const addMatchToSchedule = async () => {
    ws.setLiveSettings({
      matches_today: [...(matches_today ?? []), match as Match] ?? [],
      tournament: {
        ...(tournament as Tournament),
        participants: preloadImages
          ? await preloadMatchParticipants(match, tournament?.participants)
          : tournament?.participants ?? [],
      },
    });
  };

  const selectWinner = (id: number) => () => {
    setWinner((state) => (state === id ? null : id));
  };

  const deleteGameScore = (index: number) => () => {
    swal({
      title: `Delete Game ${index + 1}?`,
      dangerMode: true,
      buttons: ["Cancel", true],
    }).then((res) => {
      if (res) {
        setScores((state) =>
          state
            .split(",")
            .filter((_, i) => i !== index)
            .join(",")
        );
      }
    });
  };

  return (
    <Dialog open={open} classes={{ paper: c.dialog }} onClose={onClose}>
      <Sheet loading={loading}>
        <SheetHead color="red">
          <SheetHeadTitle>Match</SheetHeadTitle>
          <SheetHeadSub>{title}</SheetHeadSub>
        </SheetHead>
        <SheetBody className={c.content}>
          <SheetSection>
            <Typography variant="h4">Actions</Typography>
            <Button
              color="primary"
              variant="contained"
              onClick={selectMatch}
              style={{ margin: 10 }}
              disabled={match?.id === matchWS?.id}
            >
              Select This Match
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={addMatchToSchedule}
              style={{ margin: 10 }}
              disabled={Boolean(matches_today?.find((m) => m.id === match?.id))}
            >
              Add to Schedule
            </Button>
            <Button
              color="secondary"
              variant="contained"
              onClick={refresh}
              style={{ margin: 10 }}
            >
              Fetch Score
            </Button>
            <Button
              variant="contained"
              onClick={() => setVetoState(true)}
              style={{ margin: 10 }}
            >
              Veto
            </Button>
            <Button
              variant="contained"
              onClick={() => setPollState(true)}
              style={{ margin: 10 }}
            >
              Pick'em
            </Button>
          </SheetSection>

          <SheetSection>
            <Typography variant="h4">🆚 Manual Scores</Typography>
            {scores.split(",").map((score, i) => {
              let ss = score.match(/^(\d*)-(\d*)/);
              let team1 = ss?.length ? ss[1] : 0;
              let team2 = ss?.length ? ss[2] : 0;
              return (
                <div className={c.scores} key={i}>
                  <TextField
                    label={team(match?.player1_id ?? 0)?.org_name || "player 1"}
                    value={team1}
                    onChange={handleChange(1, i)}
                    type="number"
                  />
                  <TextField
                    label={team(match?.player2_id ?? 0)?.org_name || "player 2"}
                    value={team2}
                    onChange={handleChange(2, i)}
                    type="number"
                  />
                  {i !== 0 && (
                    <IconButton color="secondary" onClick={deleteGameScore(i)}>
                      <Delete />
                    </IconButton>
                  )}
                </div>
              );
            })}
            <Button
              variant="outlined"
              color="primary"
              style={{ marginTop: 10 }}
              onClick={addMatch}
              fullWidth
            >
              Add Match
            </Button>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                margin: 20,
                width: "full",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div style={{ paddingRight: 20 }}>Select Winner</div>
              <ButtonGroup variant="contained">
                <Button
                  onClick={selectWinner(match?.player1_id ?? 0)}
                  color={winner === match?.player1_id ? "primary" : "default"}
                >
                  {team(match?.player1_id ?? 0)?.org_name || "Team 1"}
                </Button>
                <Button
                  onClick={selectWinner(match?.player2_id ?? 0)}
                  color={winner === match?.player2_id ? "primary" : "default"}
                >
                  {team(match?.player2_id ?? 0)?.org_name || "Team 2"}
                </Button>
              </ButtonGroup>
            </div>
          </SheetSection>
          <SheetSection>
            <Typography variant="h4">🖊️ Info</Typography>
            {/* <TextField
              value={badge}
              onChange={({ currentTarget: { value } }) => setBadge(value)}
            /> */}
            <Typography variant="caption">matchId: {match?.id}</Typography>
            <Grid container spacing={2} alignItems="center">
              <Grid item sm={12} md={5}>
                <TextField
                  label="Best Of"
                  type="number"
                  value={bestOf}
                  size="small"
                  onChange={({ currentTarget: { value } }) =>
                    setBestOf(
                      parseInt(value) < 1
                        ? 1
                        : parseInt(value) > 7
                        ? 7
                        : parseInt(value)
                    )
                  }
                />
              </Grid>
              <Grid item sm={12} md={7}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDateTimePicker
                    margin="normal"
                    id="date-picker-dialog"
                    label="Match Schedule"
                    format="MM/dd/yyyy — hh:mm a"
                    value={scheduleDate}
                    onChange={handleDateChange}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
            </Grid>
          </SheetSection>
          <Button
            variant="contained"
            color="primary"
            style={{ marginTop: 10 }}
            onClick={apply}
            fullWidth
          >
            Apply Changes
          </Button>
        </SheetBody>
      </Sheet>
      <MatchPickEm
        open={pollState}
        onClose={() => setPollState(false)}
        match={match}
        title={title}
      ></MatchPickEm>
      {tournament?.game_name === "Valorant" && (
        <ControlMatchPopupVeto
          open={vetoState && tournament?.game_name === "Valorant"}
          match={match}
          title={title}
          onClose={() => setVetoState(false)}
        />
      )}
      {tournament?.game_name === "Call of Duty Mobile" && (
        <MatchVetoCodm
          open={vetoState}
          match={match}
          title={title}
          onClose={() => setVetoState(false)}
        />
      )}
    </Dialog>
  );
};

export default ControlMatchPopup;
