import RadioButton from "@components/radiobutton/RadioButton";
import RadioButtonContainer from "@components/radiobutton/RadioButtonContainer";
import Sheet from "@components/sheet/Sheet";
import SheetBody from "@components/sheet/SheetBody";
import firebase, { projectFirestore } from "@config/firebase/config";
import { DrawResult, TeamPool } from "@config/types/drawshow.type";
import { Live, ReduxState } from "@config/types/types";
import { wsContext } from "@config/websocket/WebsocketProvider";
import {
  Button,
  IconButton,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import {
  Check,
  Clear,
  Close,
  DeleteForever,
  Shuffle,
} from "@material-ui/icons";
import Add from "@material-ui/icons/Add";
import React, { useContext, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import swal from "sweetalert";
import DrawShowAddBulk from "./DrawShowAddBulk";
import DrawShowAddTeam from "./DrawShowAddTeam";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  pool: {},
  groups: {
    padding: theme.spacing(4),
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    backgroundColor: theme.palette.background.paper,
    marginBottom: theme.spacing(4),
    overflow: "auto",
    flexWrap: "wrap",
  },
  groupContainer: {
    display: "flex",
    flexDirection: "column",
    margin: theme.spacing(2),
  },
  teams: {
    borderRadius: 5,
    boxShadow: "0px 4px 4px rgba(0,0,0,.5)",
    padding: theme.spacing(2),
  },
  actions: {},
  btns: { display: "flex", padding: "5px 0" },
  btn: {
    marginRight: theme.spacing(2),
  },
  team: {
    display: "flex",
    alignItems: "center",
    margin: theme.spacing(2, 0),

    "& .logo": {
      height: 50,
      width: 50,
      backgroundSize: "contain",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      backgroundColor: "#333",
    },

    "& .name": {
      paddingLeft: theme.spacing(2),
    },
  },
}));

export function chunkArray<T = any>(arr: T[], size: number): T[][] {
  return arr.length > size
    ? [arr.slice(0, size), ...chunkArray(arr.slice(size), size)]
    : [arr];
}

interface CloudSave {
  teamPool: TeamPool;
  drawResult: string;
  size: number;
  shuffleCount: number;
}

const DrawShowPage = () => {
  const classes = useStyles();
  const [teamPool, setTeamPool] = useState<TeamPool>([]);
  const [size, setSize] = useState(10);
  const [result, setResult] = useState<DrawResult>([]);
  const [addState, setAddState] = useState(false);
  const [addBulkState, setAddBulkState] = useState(false);
  const {
    tournament,
    drawshow,
    drawshowLive = [],
    drawshowSingle = null,
    drawshowDuo,
  } = useSelector<ReduxState, Live>((state) => state.live);
  const [drawshowCloud, setDrawshowCloud] = useState<CloudSave>();
  const { setLiveSettings } = useContext(wsContext);

  useEffect(() => {
    if (!size) return;
    setResult(chunkArray(teamPool, size));
  }, [size]);

  // useEffect(() => {
  //   if (!tournament?.url) return;

  //   const path = `tournaments/${tournament?.url || "404"}/live/drawshow`;
  //   const ref = projectFirestore.doc(
  //     path
  //   ) as firebase.firestore.DocumentReference<CloudSave>;

  //   ref.get().then((snap) => {
  //     if (snap.exists) {
  //       setTeamPool(snap.data()?.teamPool ?? []);
  //       setSize(snap.data()?.size ?? 10);
  //       setResult(JSON.parse(snap.data()?.drawResult ?? ""));
  //       setDrawshowCloud(snap.data());
  //     }
  //   });
  // }, [tournament?.url]);

  useEffect(() => {
    if (!drawshow) return;
    setTeamPool(drawshow.pool);
    setResult(drawshow.result);
    setSize(drawshow.size);
  }, [drawshow]);

  function shuffleArray() {
    swal({
      title: "Shuffle Teams?",
      buttons: ["Cancel", true],
    }).then((result) => {
      if (result) {
        let array = teamPool;
        for (let i = array.length - 1; i > 0; i--) {
          const j = Math.floor(Math.random() * (array.length + 1));
          [array[i], array[j]] = [array[j], array[i]];
        }
        // setTeamPool();
        const data = array.filter(Boolean);
        setLiveSettings({
          drawshow: {
            pool: data,
            size,
            result: chunkArray(data, size),
            shuffleCount: drawshow?.shuffleCount
              ? drawshow.shuffleCount + 1
              : 1,
          },
        });
      }
    });
  }

  const save = () => {
    const path = `tournaments/${tournament?.url || "404"}/live/drawshow`;
    const promise = projectFirestore.doc(path).set({
      teamPool,
      drawResult: JSON.stringify(result),
      size: size,
      shuffleCount: drawshow?.shuffleCount ?? 0,
    });

    setLiveSettings({
      drawshow: {
        pool: teamPool,
        result: result,
        size,
        shuffleCount: drawshow?.shuffleCount ?? 0,
      },
    });

    toast.promise(promise, {
      loading: "Saving Changes...",
      error: (err) => err.message,
      success: "Saved",
    });
  };

  const loadFromCloud = () => {
    const path = `tournaments/${tournament?.url || "404"}/live/drawshow`;
    const ref = projectFirestore.doc(
      path
    ) as firebase.firestore.DocumentReference<CloudSave>;

    ref.get().then((snap) => {
      if (snap.exists) {
        setTeamPool(snap.data()?.teamPool ?? []);
        setSize(snap.data()?.size ?? 10);
        setResult(JSON.parse(snap.data()?.drawResult ?? ""));
        setDrawshowCloud(snap.data());
      }
    });
  };

  const deleteTeam = (index: number) => () => {
    swal({
      title: "Delete Team?",
      buttons: ["No", true],
    }).then((result) => {
      if (result) {
        let newTeamPool = teamPool.filter((t, i) => i !== index);
        let newResult = chunkArray(newTeamPool, size);
        setTeamPool(newTeamPool);
        setResult(newResult);
        setLiveSettings({
          drawshow: {
            pool: newTeamPool,
            result: newResult,
            size,
            shuffleCount: drawshow?.shuffleCount ?? 0,
          },
        });
      }
    });
  };

  const clear = () => {
    swal({
      title: "Clear Teams?",
      buttons: ["No", true],
    }).then((result) => {
      if (result) {
        setTeamPool([]);
        setResult([]);
      }
    });
  };

  const toggleShowHide = (i: number) => () => {
    setLiveSettings({
      drawshowLive: drawshowLive.includes(i)
        ? drawshowLive.filter((ii) => ii !== i)
        : [...drawshowLive, i],
    });
  };

  const selectGroup = (i: number) => () => {
    setLiveSettings({ drawshowSingle: drawshowSingle === i ? null : i });
  };
  const selectDuoGroup = (a?: number, b?: number) => () => {
    setLiveSettings({ drawshowDuo: { a, b } });
  };

  const abc = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

  return (
    <div className={classes.container}>
      <div className="">
        <Typography variant="h5">Control</Typography>
        <TextField
          style={{ margin: "10px 0px" }}
          value={size}
          variant="outlined"
          type="number"
          label="# of teams per group"
          onChange={(e) =>
            setSize(
              parseInt(
                !isNaN(parseInt(e.target.value)) ? e.target.value : "10"
              ) || 1
            )
          }
        />
        <div className={classes.btns}>
          <Button
            variant="contained"
            className={classes.btn}
            onClick={shuffleArray}
            startIcon={<Shuffle />}
          >
            Shuffle
          </Button>
          <Button
            variant="contained"
            className={classes.btn}
            startIcon={<Add />}
            onClick={() => setAddState(true)}
          >
            add
          </Button>
          <Button
            variant="contained"
            className={classes.btn}
            onClick={() => setAddBulkState(true)}
            startIcon={<Add />}
          >
            add Bulk
          </Button>
          <Button
            variant="contained"
            color="secondary"
            className={classes.btn}
            onClick={clear}
            startIcon={<Clear />}
          >
            Clear
          </Button>
        </div>
      </div>

      <div className={classes.btns}>
        <Button
          variant="contained"
          color="primary"
          style={{ margin: "20px 0", alignSelf: "flex-start" }}
          onClick={save}
          disabled={drawshow?.pool === drawshowCloud?.teamPool}
        >
          Save on cloud
        </Button>
        <Button
          variant="contained"
          color="primary"
          style={{ margin: "20px 0", alignSelf: "flex-start" }}
          onClick={loadFromCloud}
        >
          Load from cloud
        </Button>
      </div>

      <div className={classes.pool}>
        <Typography variant="h5"> Pool</Typography>
        <div className={classes.groups}>
          {!!teamPool.length ? (
            result.map((group, i) => (
              <div key={i} className={classes.groupContainer}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Typography variant="h4">Group {i + 1}</Typography>
                  <Button
                    style={{ marginLeft: 10 }}
                    variant="contained"
                    onClick={toggleShowHide(i)}
                    color={drawshowLive.includes(i) ? "secondary" : "primary"}
                    startIcon={drawshowLive.includes(i) ? <Close /> : <Check />}
                  >
                    {drawshowLive.includes(i)
                      ? "Click to hide"
                      : "Click to show"}
                  </Button>
                </div>
                <div className={classes.teams}>
                  {group.map((team, ii) => (
                    <div className={classes.team} key={ii}>
                      <div
                        className="logo"
                        style={{ backgroundImage: `url("${team.logo}")` }}
                      ></div>
                      <div className="name">{team.name}</div>
                      <div className="name">{team.schoolName}</div>
                      <IconButton size="small" onClick={deleteTeam(ii)}>
                        <DeleteForever fontSize="inherit" />
                      </IconButton>
                    </div>
                  ))}
                </div>
              </div>
            ))
          ) : (
            <div>No Teams yet</div>
          )}
        </div>

        <Typography variant="h5">Hide/Show single group</Typography>
        <RadioButtonContainer>
          {result.map((_, i) => (
            <RadioButton
              checked={drawshowSingle === i}
              onClick={selectGroup(i)}
              key={i}
            >
              Group {i + 1}
            </RadioButton>
          ))}
        </RadioButtonContainer>

        <Typography variant="h5" style={{ marginTop: 40 }}>
          Toggle Group Duo Boxes
        </Typography>
        <RadioButtonContainer>
          {chunkArray(
            result.map((_, i) => i),
            2
          ).map(([a, b]) => (
            <RadioButton
              checked={drawshowDuo?.a === a && drawshowDuo?.b === b}
              onClick={selectDuoGroup(a, b)}
            >
              GROUP {abc[a]}
              {b && ` & GROUP ${abc[b]}`}
            </RadioButton>
          ))}
        </RadioButtonContainer>
      </div>

      <DrawShowAddTeam
        open={addState}
        onClose={() => setAddState(false)}
        setTeamPool={setTeamPool}
        teamPool={teamPool}
        drawResult={result}
        size={size}
      />
      <DrawShowAddBulk
        open={addBulkState}
        onClose={() => setAddBulkState(false)}
        teamPool={teamPool}
        size={size}
      />
    </div>
  );
};

export default DrawShowPage;
