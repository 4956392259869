import { Caster } from "@config/types/caster.interface";
import {
  Avatar,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import React, { ChangeEvent } from "react";
import { useDocumentData } from "react-firebase-hooks/firestore";
import swal from "sweetalert";
import firebase, { projectFirestore as db } from "../../config/firebase/config";
import { PollItemProps, TalentVoteItem } from "../../config/types/types";

interface MatchPickEmTalentVotesProps {
  open: boolean;
  onClose?: () => any;
  poll?: PollItemProps;
}

const MatchPickEmTalentVotes: React.FC<MatchPickEmTalentVotesProps> = ({
  open,
  onClose = () => {},
  poll,
}) => {
  const [talents, loading] = useDocumentData<{ list: Caster[] }>(
    db
      .collection("tournaments")
      .doc(poll?.tournament_url)
      .collection("live")
      .doc("casters")
  );
  const [votes, setVotes] = React.useState<TalentVoteItem[]>([]);

  React.useEffect(() => {
    if (!talents) return;

    db.collection("tournaments")
      .doc(poll?.tournament_url)
      .collection("poll")
      .doc(poll?.match_id + "")
      .get()
      .then((doc) => {
        if (doc.exists) {
          const data = doc?.data() as PollItemProps;

          if (data.talent_votes.length) {
            setVotes(data.talent_votes);
          } else {
            setVotes(talents.list.map((t) => ({ caster: t })));
          }
        }
      });
  }, [talents]);

  const vote =
    (team: "team1" | "team2" | undefined, index: number) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.checked) {
        setVotes(votes.map((v, i) => (i !== index ? v : { ...v, vote: team })));
      } else {
        setVotes(
          votes.map((v, i) => (i !== index ? v : { ...v, vote: undefined }))
        );
      }
    };

  const save = () => {
    db.collection("tournaments")
      .doc(poll?.tournament_url)
      .collection("poll")
      .doc(poll?.match_id + "")
      .set({ ...poll, talent_votes: votes })
      .then(() => {
        swal({
          title: "Saved Succesfully",
          icon: "success",
          timer: 3000,
        });
        onClose();
      });
  };

  const resetVotes = () => {
    setVotes(talents?.list.map((t) => ({ caster: t })) ?? []);
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Talent Pick'Em</DialogTitle>
      <DialogContent>
        {loading ? (
          <LinearProgress />
        ) : Boolean(votes.length) ? (
          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Talent</TableCell>
                  <TableCell>Team</TableCell>
                  <TableCell>{poll?.team1?.university_acronym}</TableCell>
                  <TableCell>{poll?.team2?.university_acronym}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {votes.map((talentVote, i) => (
                  <TableRow>
                    <TableCell>{talentVote.caster.name}</TableCell>
                    <TableCell>
                      <Avatar
                        src={
                          poll && talentVote.vote && poll[talentVote.vote]?.logo
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <Checkbox
                        size="small"
                        color="primary"
                        checked={talentVote.vote === "team1"}
                        onChange={vote("team1", i)}
                      />
                    </TableCell>
                    <TableCell>
                      <Checkbox
                        size="small"
                        color="primary"
                        checked={talentVote.vote === "team2"}
                        onChange={vote("team2", i)}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Typography variant="caption">No Talents found</Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button color="secondary" variant="contained" onClick={resetVotes}>
          reset
        </Button>
        <Button
          disabled={poll?.talent_votes === votes}
          color="primary"
          variant="contained"
          onClick={save}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MatchPickEmTalentVotes;
