import { Caster } from "@config/types/caster.interface";
import {
  makeStyles,
  Paper,
  Avatar,
  Badge,
  Typography,
  Chip,
  Button,
} from "@material-ui/core";
import axios from "axios";
import React from "react";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { useSelector } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import addCors from "@utils/addCors";
import Sheet from "@components/sheet/Sheet";
import SheetBody from "@components/sheet/SheetBody";
import SheetFooter from "@components/sheet/SheetFooter";
import SheetHead from "@components/sheet/SheetHead";
import SheetHeadTitle from "@components/sheet/SheetHeadTitle";
import SheetSection from "@components/sheet/SheetSection";
import { projectFirestore as db } from "@config/firebase/config";
import { ReduxState } from "@config/types/types";
import { wsContext } from "@config/websocket/WebsocketProvider";
import toBase64 from "@utils/toBase64";
import { useAsync as asyncHook } from "@utils/asyncHook";
import swal from "sweetalert";
import blobber from "@utils/blobber";
import toast from "react-hot-toast";
import getFileType from "@utils/getMetadata";
import getMetadata from "@utils/getMetadata";
import useSettings from "@lib/useSettings";

interface CasterSelectionProps extends RouteComponentProps {
  alt?: boolean;
}

const makeCompStyles = makeStyles((theme) => ({
  casters: {
    display: "grid",
    gap: "10px",
    gridTemplateColumns: "repeat(auto-fill, minmax(115px, 1fr))",
  },
  caster: {
    cursor: "pointer",
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    position: "relative",
    "&:hover": {
      backgroundColor: theme.palette.background.default,
    },
    "& .name": { marginTop: 10 },
    "& .ign": { color: "#999" },
    "& .photo": {
      height: theme.spacing(20),
      width: theme.spacing(20),
    },
    "& .badge": {
      position: "absolute",
      borderRadius: "50%",
      height: 30,
      width: 30,
      backgroundColor: theme.palette.primary.main,
      top: -15,
      right: -15,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      color: "#fff",
      zIndex: 10,
    },
  },
  body: {
    display: "flex",
    flexDirection: "column",
  },
}));

const CasterSelection: React.FC<CasterSelectionProps> = ({ history, alt }) => {
  const classes = makeCompStyles();
  const {
    tournament,
    casters: castersWS,
    casters_alt,
  } = useSelector((state: ReduxState) => state.live);
  const ws = React.useContext(wsContext);
  const [selection, setSelection] = React.useState<Caster[]>([]);
  const [castersDoc, loading] = useDocumentData<{ list: Caster[] }>(
    db
      .collection("tournaments")
      .doc(tournament?.url)
      .collection("live")
      .doc("casters")
  );
  const { preloadImages } = useSettings();

  React.useEffect(() => {
    if (!castersWS && !casters_alt) return;
    if (alt) {
      setSelection(casters_alt ?? []);
    } else {
      setSelection(castersWS ?? []);
    }
  }, [casters_alt, castersWS, setSelection]);

  const handleClick = (caster: Caster) => async () => {
    if (selection.some((c) => c.ign === caster.ign)) {
      setSelection(selection.filter((c) => c.ign !== caster.ign));
    } else {
      const url = caster.photo_url ?? "";
      const base64 = preloadImages ? await blobber(url) : "";
      let newCaster: Caster = {
        ...caster,
        photo_base64: base64,
      };

      setSelection([...selection, newCaster]);
    }
  };

  const removeCaster = (caster: Caster) => () => {
    setSelection(selection.filter((c) => c.ign !== caster.ign));
  };

  const getSelectionIndex = (caster: Caster) => {
    if (selection.some((c) => c.ign === caster.ign)) {
      return selection.findIndex((c) => c.ign === caster.ign) + 1;
    }
  };

  const apply = () => {
    if (!alt) {
      ws.setLiveSettings({ casters: selection });
    } else {
      ws.setLiveSettings({ casters_alt: selection });
    }
  };

  return (
    <Sheet loading={loading}>
      <SheetHead color={alt ? "blue" : "red"}>
        <SheetHeadTitle>
          Broadcast Casters {alt ? "Alternate" : "Main"}
        </SheetHeadTitle>
      </SheetHead>
      <SheetBody className={classes.body}>
        <div className={classes.casters}>
          {Boolean(castersDoc?.list?.length) ? (
            castersDoc?.list.map((caster) => (
              <Paper
                key={caster.ign}
                className={classes.caster}
                variant="outlined"
                onClick={handleClick(caster)}
              >
                <Avatar
                  variant="rounded"
                  src={caster.photo_url}
                  className="photo"
                />
                <Typography align="center" className="name">
                  {caster.name}
                </Typography>
                <Typography align="center" variant="caption" className="ign">
                  {caster.ign}
                </Typography>
                {Boolean(getSelectionIndex(caster)) && (
                  <div className="badge">{getSelectionIndex(caster)}</div>
                )}
              </Paper>
            ))
          ) : (
            <div>No Casters set</div>
          )}
        </div>

        {Boolean(selection?.length) && (
          <SheetSection style={{ marginTop: 20 }}>
            <Typography variant="h4">Selection</Typography>
            <div className="list">
              {selection.map((caster) => (
                <Chip
                  key={caster.ign}
                  label={caster.ign}
                  onDelete={removeCaster(caster)}
                  style={{ marginRight: 5 }}
                  avatar={
                    <Avatar
                      src={caster.photo_base64 || caster.photo_url}
                      alt={caster.ign}
                    />
                  }
                />
              ))}
            </div>
          </SheetSection>
        )}

        <Button
          style={{ alignSelf: "center", marginTop: selection?.length ? 0 : 20 }}
          variant="outlined"
          color="primary"
          disabled={alt ? casters_alt === selection : castersWS === selection}
          onClick={apply}
        >
          Apply
        </Button>
      </SheetBody>
      <SheetFooter>
        add ?alt=1 to url to activate the alternate casters
      </SheetFooter>
    </Sheet>
  );
};

export default withRouter(CasterSelection);
