import { projectFirestore, projectStorage } from "@config/firebase/config";
import { DialogProps } from "@config/types/dialogsTypes";
import { DrawResult, DrawTeam, TeamPool } from "@config/types/drawshow.type";
import { Live, ReduxState } from "@config/types/types";
import { wsContext } from "@config/websocket/WebsocketProvider";
import {
  Button,
  ButtonBase,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@material-ui/core";
import React, { ChangeEvent, useContext, useState } from "react";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { chunkArray } from "./DrawShowPage";

interface DrawShowAddTeamProps extends DialogProps {
  setTeamPool: React.Dispatch<React.SetStateAction<TeamPool>>;
  teamPool: TeamPool;
  drawResult: DrawResult;
  size: number;
}

const DrawShowAddTeam = ({
  teamPool,
  size,
  open,
  onClose,
}: DrawShowAddTeamProps) => {
  const [form, setForm] = useState<DrawTeam>({
    name: "",
    logo: "",
    schoolName: "",
  });
  const { drawshow } = useSelector<ReduxState, Live>((state) => state.live);
  const { setLiveSettings } = useContext(wsContext);
  const [file, setFile] = useState<{ file?: File; url?: string }>({});

  const fileSelect =
    (destination: string = "DrawShow/") =>
    ({ target: { files, name } }: React.ChangeEvent<HTMLInputElement>) => {
      const image = files?.length ? files[0] : null;
      if (image) {
        setFile({ file: image, url: URL.createObjectURL(image) });
        uploadFile({ file: image, destination, name });
      }
    };

  const uploadFile = ({
    file,
    destination,
    name,
  }: {
    file: File;
    destination: string;
    name: string;
  }) => {
    const storageRef = projectStorage.ref(
      destination + Date.now + "_" + file.name
    );

    const toastId = toast.loading("Uploading...");
    storageRef.put(file).then(async (snap) => {
      toast.success("Upload finished!", { id: toastId });
      const url = await snap.ref.getDownloadURL();
      setForm((state) => ({ ...state, [name]: url }));
    });
  };

  const handleText = (e: ChangeEvent<HTMLInputElement>) =>
    setForm((state) => ({ ...state, [e.target.name]: e.target.value }));

  const save = () => {
    const pool = [...teamPool, form];
    const result = chunkArray(pool, size);

    setLiveSettings({
      drawshow: {
        pool,
        result,
        size,
        shuffleCount: drawshow?.shuffleCount ?? 0,
      },
    });

    onClose();
  };
  return (
    <Dialog {...{ open, onClose }} maxWidth="sm" fullWidth>
      <DialogTitle>Add Team</DialogTitle>
      <DialogContent>
        <div style={{ display: "flex" }}>
          <ButtonBase
            component="label"
            style={{
              backgroundImage: `url("${file.url || form.logo}")`,
              backgroundColor: "#fff",
              backgroundSize: "contain",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              height: 100,
              width: 100,
              borderRadius: 10,
              color: "#000",
            }}
          >
            <input
              hidden
              type="file"
              accept="image/*"
              name="logo"
              onChange={fileSelect("DrawShow/")}
            />
            {(!file.url || !form.logo) && "Logo"}
          </ButtonBase>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              paddingLeft: 20,
              flex: 1,
            }}
          >
            <TextField
              value={form.name}
              onChange={handleText}
              label="Name"
              name="name"
              variant="outlined"
              fullWidth
              style={{ marginBottom: 15 }}
            />
            <TextField
              value={form.schoolName}
              onChange={handleText}
              label="School Name"
              name="schoolName"
              variant="outlined"
              fullWidth
            />
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={save}
          disabled={
            !form.name || teamPool.map((t) => t.name).includes(form.name)
          }
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DrawShowAddTeam;
