import React, { useContext } from "react";
import { useSelector } from "react-redux";
import {
  Participant,
  ParticipantElement,
  ReduxState,
  Tournament,
} from "../../config/types/types";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { projectFirestore as db } from "../../config/firebase/config";

import Sheet from "../../comps/sheet/Sheet";
import SheetBody from "../../comps/sheet/SheetBody";
import SheetHead from "../../comps/sheet/SheetHead";
import SheetHeadTitle from "../../comps/sheet/SheetHeadTitle";
import SheetSection from "../../comps/sheet/SheetSection";
import SheetFooter from "../../comps/sheet/SheetFooter";
import SheetHeadSub from "../../comps/sheet/SheetHeadSub";

import EditDialog from "./ParticipantEdit";

import { Button, makeStyles, Typography } from "@material-ui/core";
import CloudOutlinedIcon from "@material-ui/icons/CloudOutlined";
import { challongeApi } from "@utils/challonge";
import toast from "react-hot-toast";
import { wsContext } from "@config/websocket/WebsocketProvider";

const makeCompStyles = makeStyles((theme) => ({
  participants: {
    display: "grid",
    gap: "15px",
    gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
  },
  participant: {
    margin: 0,
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    "& .logo": {
      fontWeight: 300,
      height: 70,
      width: 70,
      marginRight: theme.spacing(3),
      backgroundSize: "contain",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      filter: "drop-shadow(0px 8px 4px rgba(0,0,0,.5))",
    },
  },
  details: {
    display: "flex",
    flexDirection: "column",
    "& .name": {
      fontWeight: 300,
    },
    "& .school": {
      color: "#999",
    },
  },
  reload: {
    margin: 10,
  },
}));

const ParticipantsPage: React.FC = () => {
  const classes = makeCompStyles();
  const { tournament: tournamentWS } = useSelector(
    (state: ReduxState) => state.live
  );
  const { setLiveSettings } = useContext(wsContext);
  const tournamentRef = db.collection("tournaments").doc(tournamentWS?.url);
  const [tournamentFS, loading] = useDocumentData<Tournament>(tournamentRef);
  const [selected, setSelected] = React.useState<Participant | undefined>();
  const [editState, setEditState] = React.useState<boolean>(false);

  const editParticipant =
    ({ participant }: { participant: Participant }) =>
    () => {
      setSelected(participant);
      setEditState(true);
    };

  const onClick = () => {
    const promise = challongeApi.get<ParticipantElement[]>(
      `/tournaments/${tournamentWS?.id}/participants.json`
    );

    toast.promise(promise, {
      loading: "fetching data from challonge",
      error: (err) => err.message,
      success: (res) => {
        const DATA = res.data;
        const PARTICIPANTS = DATA.map((p) => p.participant);

        const firestoreTask = db
          .collection("tournaments")
          .doc(tournamentWS?.url)
          .set({
            ...tournamentWS,
            participants: (tournamentWS?.participants ?? []).map(
              ({ logo_base64, university_banner_base64, ...p }) => ({
                ...p,
                ...PARTICIPANTS.find((pp) => pp.id === p.id),
              })
            ),
          });

        toast.promise(firestoreTask, {
          loading: "Saving changes...",
          error: (err) => err.message,
          success: () => {
            setLiveSettings({
              tournament: {
                ...(tournamentWS as Tournament),
                participants: (tournamentWS?.participants ?? []).map((p) => ({
                  ...p,
                  ...PARTICIPANTS.find((pp) => pp.id === p.id),
                })),
              },
            });

            return "Saved Successfully";
          },
        });

        return "Successfully Updated challonge data";
      },
    });
  };

  return (
    <div>
      <Button className={classes.reload} variant="outlined" onClick={onClick}>
        Reload data from challonge
      </Button>
      <Sheet loading={loading}>
        <SheetHead>
          <SheetHeadTitle>Participants</SheetHeadTitle>
          <SheetHeadSub>
            List of teams / players for this tournament
          </SheetHeadSub>
        </SheetHead>
        <SheetBody>
          <div className={classes.participants}>
            {tournamentFS?.participants.map((p, i) => (
              <SheetSection
                key={p.id}
                className={classes.participant}
                onClick={editParticipant({ participant: p })}
              >
                <div
                  className="logo"
                  style={{
                    backgroundImage: `url("${p.logo}")`,
                    border: Boolean(p.logo)
                      ? "none"
                      : "1px solid rgba(0,0,0,.1)",
                  }}
                ></div>
                <div className={classes.details}>
                  <div className="name">{p.org_name || p.display_name}</div>
                  <Typography variant="caption" className="school">
                    {p.university_name}
                  </Typography>
                </div>
              </SheetSection>
            ))}
          </div>
        </SheetBody>
        <SheetFooter>
          <CloudOutlinedIcon />
          Uses Database
        </SheetFooter>
      </Sheet>
      <EditDialog
        tournament={tournamentFS}
        participant={selected}
        open={editState}
        onClose={() => setEditState(false)}
      />
    </div>
  );
};

export default ParticipantsPage;
