export default function (url: string) {
  const re = /^https\:\/\/(.*\.\w*)\/(.*)/;

  const re2 = /https\:\/\/firebasestorage\.googleapis\.com\/(.*)/;
  const cors = "https://noki-cors.herokuapp.com/";
  if (re2.test(url)) {
    return url.replace(re2, `${cors}firebasestorage.googleapis.com:443/$1`);
  }

  if (re.test(url)) {
    const newUrl = url.replace(re, `${cors}$1:443/$2`);
    return newUrl;
  } else {
    return url.replace(/.*/, `${cors}$0`);
  }
}
