import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListSubheader,
  makeStyles,
  Menu,
  Typography,
} from "@material-ui/core";
import React, {
  Dispatch,
  FC,
  MouseEvent,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { useSelector } from "react-redux";
import swal from "sweetalert";
import { projectFirestore } from "../../../config/firebase/config";
import {
  Live,
  Match,
  ReduxState,
  Tournament,
} from "../../../config/types/types";
import {
  VetoCodm,
  maps,
  CodmMap,
  CodmMode,
} from "../../../config/types/vetoCodm.interface";
import { wsContext } from "../../../config/websocket/WebsocketProvider";

interface MatchVetoCodmProps extends DialogProps {
  onClose?: () => void;
  match?: Match;
  title: string;
}

const makeComponentStyles = makeStyles((theme) => ({
  grid: {
    display: "grid",
    gridTemplateColumns: "repeat(10,1fr)",
    gridTemplateRows: "50px 300px 50px 85px",
    gap: 10,

    "& > *": {
      border: "1px solid rgba(0,0,0,.2)",
    },

    "& .map": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      fontWeight: "bold",
      gridColumn: "auto / span 2",
    },

    "& .pick": {
      backgroundSize: "cover",
      backgroundPosition: "center",
      fontWeight: "bold",
      //   textShadow: "0px 4px 8px rgba(0,0,0,.5)",
      fontSize: 20,

      gridColumn: "auto / span 2",
    },

    "& .mode": {
      gridColumn: "auto / span 2",
    },

    "& .ban": {
      backgroundSize: "cover",
      backgroundPosition: "center",
      fontWeight: "bold",
      textShadow: "0px 4px 8px rgba(0,0,0,.5)",
    },

    "& .btn-text": {
      backgroundColor: "rgba(0,0,0,.5)",
    },
  },
  menuGrid: {
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)",
  },
}));

const MatchVetoCodm: FC<MatchVetoCodmProps> = ({
  open,
  onClose = () => {},
  match,
  title,
}) => {
  const classes = makeComponentStyles();
  const [veto, setVeto] = useState<VetoCodm>({});
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [anchorEl2, setAnchorEl2] = useState<null | HTMLElement>(null);
  const [mapSelDetails, setMapSelDetails] = useState({
    map_number: "map1" as keyof VetoCodm,
    actionType: "pick" as "pick" | "ban1" | "ban2",
  });
  const [modeSelDetails, setModeSelDetails] = useState({
    map_number: "map1" as keyof VetoCodm,
    mode: "mode" as "mode" | "ban_mode",
  });
  const [loading, setLoading] = useState<boolean>(false);

  const {
    tournament,
    match: matchWS,
    matches_today,
  } = useSelector((state: ReduxState) => state.live);
  const ws = useContext(wsContext);

  useEffect(() => {
    if (!match?.veto_codm) setVeto({});
    setVeto({ ...match?.veto_codm });
  }, [match]);

  const save = () => {
    setLoading(true);

    projectFirestore
      .collection("tournaments")
      .doc(tournament?.url ?? "")
      .set({
        ...tournament,
        matches: tournament?.matches.map((m) =>
          m.id === match?.id ? { ...m, veto_codm: veto } : m
        ),
        participants: tournament?.participants?.map(
          ({ logo_base64, ...p }) => p
        ),
      } as Partial<Tournament>)
      .then(() => {
        setLoading(false);
        ws.setLiveSettings({
          tournament: {
            ...tournament,
            matches: tournament?.matches.map((m) =>
              m.id === match?.id ? { ...m, veto_codm: veto } : m
            ),
          },
          match: {
            ...(matchWS?.id === match?.id
              ? {
                  ...matchWS,
                  veto_codm: veto,
                }
              : matchWS),
          },
          matches_today: matches_today?.map((m) =>
            m.id === match?.id ? { ...m, veto_codm: veto } : m
          ),
        } as Partial<Live>);
        onClose();
      });
  };

  const closeCheck = () => {
    swal({
      title: "Close Veto Dialog?",
      text: "All unsaved changes will be lost",
      icon: "warning",
      dangerMode: true,
      buttons: ["Go back to veto", true],
    }).then((res) => {
      if (res) {
        onClose();
      }
    });
  };

  const selectMap =
    (details: Partial<MapSelectionProps>) =>
    (e: MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(e.currentTarget);
      setMapSelDetails({ ...mapSelDetails, ...details });
    };

  const selectMode =
    (details: Partial<ModeSelectionProps>) =>
    (e: MouseEvent<HTMLButtonElement>) => {
      setAnchorEl2(e.currentTarget);
      setModeSelDetails({ ...modeSelDetails, ...details });
    };

  return (
    <Dialog open={open} onClose={closeCheck} maxWidth="md" fullWidth>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <div className={classes.grid}>
          {/* First row */}
          <Typography variant="h4" className="map">
            Map 1
          </Typography>
          <Typography variant="h4" className="map">
            Map 2
          </Typography>
          <Typography variant="h4" className="map">
            Map 3
          </Typography>
          <Typography variant="h4" className="map">
            Map 4
          </Typography>
          <Typography variant="h4" className="map">
            Map 5
          </Typography>

          {/* Pick Row */}
          <Button
            disabled={loading}
            className="pick"
            variant="outlined"
            classes={{ label: "btn-text" }}
            style={{
              backgroundImage: `url("${maps[veto?.map1?.pick as CodmMap]}")`,
            }}
            onClick={selectMap({ map_number: "map1", actionType: "pick" })}
          >
            {veto.map1?.pick || "PICK"}
          </Button>

          <Button
            disabled={loading}
            className="pick"
            variant="outlined"
            classes={{ label: "btn-text" }}
            style={{
              backgroundImage: `url("${maps[veto?.map2?.pick as CodmMap]}")`,
            }}
            onClick={selectMap({ map_number: "map2", actionType: "pick" })}
          >
            {veto.map2?.pick || "PICK"}
          </Button>

          <Button
            disabled={loading}
            className="pick"
            variant="outlined"
            classes={{ label: "btn-text" }}
            style={{
              backgroundImage: `url("${maps[veto?.map3?.pick as CodmMap]}")`,
            }}
            onClick={selectMap({ map_number: "map3", actionType: "pick" })}
          >
            {veto.map3?.pick || "PICK"}
          </Button>

          <Button
            disabled={loading}
            className="pick"
            variant="outlined"
            classes={{ label: "btn-text" }}
            style={{
              backgroundImage: `url("${maps[veto?.map4?.pick as CodmMap]}")`,
            }}
            onClick={selectMap({ map_number: "map4", actionType: "pick" })}
          >
            {veto.map4?.pick || "PICK"}
          </Button>

          <Button
            disabled={loading}
            className="pick"
            variant="outlined"
            classes={{ label: "btn-text" }}
            style={{
              backgroundImage: `url("${maps[veto?.map5?.pick as CodmMap]}")`,
            }}
            onClick={selectMap({ map_number: "map5", actionType: "pick" })}
          >
            {veto.map5?.pick || "PICK"}
          </Button>

          {/* MODES */}
          <Button
            disabled={loading}
            className="mode"
            onClick={selectMode({ map_number: "map1", mode: "mode" })}
          >
            {veto?.map1?.mode || "MODE"}
          </Button>
          <Button
            disabled={loading}
            className="mode"
            onClick={selectMode({ map_number: "map2", mode: "mode" })}
          >
            {veto?.map2?.mode || "MODE"}
          </Button>
          <Button
            disabled={loading}
            className="mode"
            onClick={selectMode({ map_number: "map3", mode: "mode" })}
          >
            {veto?.map3?.mode || "MODE"}
          </Button>
          <Button
            disabled={loading}
            className="mode"
            onClick={selectMode({ map_number: "map4", mode: "mode" })}
          >
            {veto?.map4?.mode || "MODE"}
          </Button>
          <Button
            disabled={loading}
            className="mode"
            onClick={selectMode({ map_number: "map5", mode: "mode" })}
          >
            {veto?.map5?.mode || "MODE"}
          </Button>

          {/* Bans */}
          <Button
            disabled={loading}
            className="ban"
            variant="outlined"
            classes={{ label: "btn-text" }}
            onClick={selectMap({ map_number: "map1", actionType: "ban1" })}
            style={{
              backgroundImage: `url("${maps[veto?.map1?.ban1 as CodmMap]}")`,
            }}
          >
            {veto?.map1?.ban1 || "Ban 1"}
          </Button>
          <Button
            disabled={loading}
            className="ban"
            variant="outlined"
            classes={{ label: "btn-text" }}
            onClick={selectMap({ map_number: "map1", actionType: "ban2" })}
            style={{
              backgroundImage: `url("${maps[veto?.map1?.ban2 as CodmMap]}")`,
            }}
          >
            {veto?.map1?.ban2 || "Ban 2"}
          </Button>
          <Button
            disabled={loading}
            className="ban"
            variant="outlined"
            classes={{ label: "btn-text" }}
            onClick={selectMap({ map_number: "map2", actionType: "ban1" })}
            style={{
              backgroundImage: `url("${maps[veto?.map2?.ban1 as CodmMap]}")`,
            }}
          >
            {veto?.map2?.ban1 || "Ban 1"}
          </Button>
          <Button
            disabled={loading}
            className="ban"
            variant="outlined"
            classes={{ label: "btn-text" }}
            onClick={selectMap({ map_number: "map2", actionType: "ban2" })}
            style={{
              backgroundImage: `url("${maps[veto?.map2?.ban2 as CodmMap]}")`,
            }}
          >
            {veto?.map2?.ban2 || "Ban 2"}
          </Button>
          <Button
            disabled={loading}
            className="ban"
            variant="outlined"
            classes={{ label: "btn-text" }}
            onClick={selectMap({ map_number: "map3", actionType: "ban1" })}
            style={{
              backgroundImage: `url("${maps[veto?.map3?.ban1 as CodmMap]}")`,
            }}
          >
            {veto?.map3?.ban1 || "Ban 1"}
          </Button>
          <Button
            disabled={loading}
            className="ban"
            variant="outlined"
            classes={{ label: "btn-text" }}
            onClick={selectMap({ map_number: "map3", actionType: "ban2" })}
            style={{
              backgroundImage: `url("${maps[veto?.map3?.ban2 as CodmMap]}")`,
            }}
          >
            {veto?.map3?.ban2 || "Ban 2"}
          </Button>

          <Button
            disabled={loading}
            className="mode"
            onClick={selectMode({ map_number: "map1", mode: "ban_mode" })}
          >
            {veto?.map1?.ban_mode || "MODE"}
          </Button>
          <Button
            disabled={loading}
            className="mode"
            onClick={selectMode({ map_number: "map2", mode: "ban_mode" })}
          >
            {veto?.map2?.ban_mode || "MODE"}
          </Button>
          <Button
            disabled={loading}
            className="mode"
            onClick={selectMode({ map_number: "map3", mode: "ban_mode" })}
          >
            {veto?.map3?.ban_mode || "MODE"}
          </Button>

          <Button
            disabled={loading}
            variant="contained"
            color="primary"
            style={{
              gridRow: "4 / 6",
              gridColumn: "7 / 11",
              border: "none",
              fontSize: 40,
            }}
            onClick={save}
          >
            SAVE
          </Button>
        </div>
      </DialogContent>

      <DialogActions>
        <Button onClick={() => setVeto({})}>Clear</Button>
      </DialogActions>

      <ModeSelection
        anchorEl={anchorEl2}
        map_number={modeSelDetails.map_number}
        cb={setVeto}
        mode={modeSelDetails.mode}
        onClose={() => setAnchorEl2(null)}
      />

      <MapSelection
        anchorEl={anchorEl}
        map_number={mapSelDetails.map_number}
        cb={setVeto}
        actionType={mapSelDetails.actionType}
        onClose={() => setAnchorEl(null)}
      />
    </Dialog>
  );
};

export default MatchVetoCodm;

interface MapSelectionProps {
  map_number: keyof VetoCodm;
  actionType: "pick" | "ban1" | "ban2";
  cb: Dispatch<SetStateAction<VetoCodm>>;
  anchorEl: HTMLElement | null;
  onClose: () => void;
}

const MapSelection: FC<MapSelectionProps> = ({
  map_number,
  actionType,
  cb,
  anchorEl,
  onClose,
}) => {
  const classes = makeComponentStyles();

  const selectMap = (map: CodmMap) => () => {
    cb((cb) => ({
      ...cb,
      [map_number]: { ...cb[map_number], [actionType]: map },
    }));
    onClose();
  };

  return (
    <Menu
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={onClose}
      classes={{ list: classes.menuGrid }}
    >
      <ListSubheader
        style={{
          gridColumnStart: 1,
          gridColumnEnd: 4,
          textAlign: "center",
          fontWeight: "bold",
          textTransform: "uppercase",
        }}
      >
        {map_number + " " + actionType}
      </ListSubheader>
      {Object.keys(maps).map((m) => (
        <ListItem button onClick={selectMap(m as CodmMap)}>
          <ListItemAvatar>
            <Avatar src={maps[m as CodmMap]} variant="rounded" />
          </ListItemAvatar>
          <ListItemText primary={m} />
        </ListItem>
      ))}
    </Menu>
  );
};

interface ModeSelectionProps {
  map_number: keyof VetoCodm;
  mode: "mode" | "ban_mode";
  cb: Dispatch<SetStateAction<VetoCodm>>;
  anchorEl: HTMLElement | null;
  onClose: () => void;
}

const ModeSelection: FC<ModeSelectionProps> = (props) => {
  const selectMode = (mode: CodmMode) => () => {
    props.cb((veto) => ({
      ...veto,
      [props.map_number]: { ...veto[props.map_number], [props.mode]: mode },
    }));
    props.onClose();
  };
  return (
    <Menu
      onClose={props.onClose}
      anchorEl={props.anchorEl}
      open={Boolean(props.anchorEl)}
      anchorOrigin={{ horizontal: "center", vertical: "center" }}
      transformOrigin={{
        horizontal: "center",
        vertical: "top",
      }}
    >
      {Object.values(CodmMode).map((m) => (
        <ListItem button onClick={selectMode(m)}>
          {m}
        </ListItem>
      ))}
    </Menu>
  );
};
