import { Settings, SettingsFns } from "@config/types/settings.interface";
import { createContext, FC, useState } from "react";

export const settingsContext = createContext<Settings & SettingsFns>({
  preloadImages: true,
  togglePreload: () => {},
});

export const SettingsProvider: FC = ({ children }) => {
  const [preloadImages, setPreloadImages] = useState(true);

  const togglePreload = () => setPreloadImages((state) => !state);
  return (
    <settingsContext.Provider
      value={{
        preloadImages,
        togglePreload,
      }}
    >
      {children}
    </settingsContext.Provider>
  );
};
