import { DialogProps } from "@config/types/dialogsTypes";
import { DrawResult, DrawTeam, TeamPool } from "@config/types/drawshow.type";
import { Live, ReduxState } from "@config/types/types";
import { wsContext } from "@config/websocket/WebsocketProvider";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@material-ui/core";
import React, { useContext, useState } from "react";
import { useSelector } from "react-redux";
import { chunkArray } from "./DrawShowPage";

interface DrawShowAddTeamProps extends DialogProps {
  teamPool: TeamPool;
  size: number;
}

const DrawShowAddBulk = ({
  teamPool,
  open,
  onClose,
  size,
}: DrawShowAddTeamProps) => {
  const [form, setForm] = useState("");

  const { drawshow } = useSelector<ReduxState, Live>((state) => state.live);
  const { setLiveSettings } = useContext(wsContext);

  const save = () => {
    const arr = Array.from(new Set(form.split("\n")));
    const cleanArr = arr.map((x) => x.trim()).filter(Boolean);
    const filteredArr = getDifference(
      cleanArr,
      teamPool.map((x) => x.name)
    );

    const newTeams = filteredArr.map((x) => ({
      name: x,
      schoolName: "",
      logo: "",
    }));

    const pool = [...teamPool, ...newTeams];
    setLiveSettings({
      drawshow: {
        pool,
        result: chunkArray(pool, size),
        size,
        shuffleCount: drawshow?.shuffleCount,
      },
    });
    onClose();
  };

  const getDifference = (arr1: string[], arr2: string[]) => {
    return arr1.filter((x) => !arr2.includes(x));
  };
  return (
    <Dialog {...{ open, onClose }} maxWidth="sm" fullWidth>
      <DialogTitle>Add Team</DialogTitle>
      <DialogContent>
        <div className="">
          <TextField
            fullWidth
            multiline
            variant="outlined"
            rows={10}
            label="teams"
            helperText="Separate teams by line"
            value={form}
            onChange={(e) => setForm(e.target.value)}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={save}>
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DrawShowAddBulk;
