import { Button, Grid, makeStyles } from "@material-ui/core";
import React from "react";
import Sheet from "../../comps/sheet/Sheet";
import SheetBody from "../../comps/sheet/SheetBody";
import SheetHead from "../../comps/sheet/SheetHead";
import SheetHeadTitle from "../../comps/sheet/SheetHeadTitle";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";

const mcs = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(2),
  },
}));

/**
 *
 * QoL update:
 * Make the links dynamic and saved in database
 *
 * Right now all links are manually typed here
 *
 */

const data = [
  {
    name: "UAC S3",
    baseUrl: "https://uac-s3.acadarena.com",
    links: [
      {
        name: "CasterCam",
        url: "/castercam",
      },
      {
        name: "Left Banner",
        url: "/match",
      },
      {
        name: "Right Banner",
        url: "/module",
      },
      {
        name: "Winner Flash (on top of ingame scene)",
        url: "/winner1",
      },
      {
        name: "Lower Thirds ",
        url: "/lt",
      },
      {
        name: "Ingame Valorant",
        url: "/ingame",
      },
      {
        name: "Ingame Wild Rift",
        url: "/ingame/wr",
      },
      {
        name: "VETO Screen",
        url: "/veto",
      },
      {
        name: "VS Screen",
        url: "/vs",
      },
      {
        name: "Team 1",
        url: "/team1",
      },
      {
        name: "Team 2",
        url: "/team2",
      },
      {
        name: "Talent 1",
        url: "/caster/1",
      },
      {
        name: "Talent 2",
        url: "/caster/2?flipX=1",
      },
      {
        name: "Talent 3",
        url: "/caster/3",
      },
      {
        name: "Talent 4",
        url: "/caster/4?flipX=1",
      },
      {
        name: "GUEST",
        url: "/caster/0",
      },
      {
        name: "LODI of the Game Vertical Banner",
        url: "/lodi",
      },
      {
        name: "LODI of the Game LT",
        url: "/lodi2",
      },
    ],
  },
  {
    name: "NCO",
    baseUrl: "https://aa-nco.web.app",
    links: [
      {
        name: "CasterCam",
        url: "/castercam",
      },
      {
        name: "Modular Banner",
        url: "/content",
      },
      {
        name: "Timer",
        url: "/timer",
      },
      {
        name: "Ticker",
        url: "/ticker",
      },
      {
        name: "Winner Flash Screen",
        url: "/winner",
      },
      {
        name: "Champion Flash Screen",
        url: "/champion",
      },
      {
        name: "Ingame League of Legends",
        url: "/ingame/lol",
      },
      {
        name: "Ingame CODM",
        url: "/ingame/codm",
      },
      {
        name: "Ingame MLBB",
        url: "/ingame/mlbb",
      },
      {
        name: "Draft",
        url: "/drafting",
      },
      {
        name: "VS Scren",
        url: "/vs",
      },

      {
        name: "logo",
        url: "/logo",
      },
      {
        name: "CODM Veto",
        url: "/veto/codm",
      },
    ],
  },
];

const LinkPage = () => {
  const classes = mcs();

  const goto = (url: string) => () => {
    window.open(url, "_blank");
  };
  return (
    <Grid container spacing={6}>
      {data.map((tournament) => (
        <Grid key={tournament.name} item xs={12} md={6} lg={4} xl={3}>
          <Sheet>
            <SheetHead>
              <SheetHeadTitle>{tournament.name}</SheetHeadTitle>
            </SheetHead>
            <SheetBody>
              <div style={{ display: "flex", flexDirection: "column" }}>
                {tournament.links.map((link, i) => (
                  <Button
                    key={i}
                    variant="outlined"
                    endIcon={<OpenInNewIcon />}
                    color="primary"
                    className={classes.button}
                    onClick={goto(
                      `${
                        process.env.NODE_ENV === "development"
                          ? "localhost:3001"
                          : tournament.baseUrl
                      }${link.url}`
                    )}
                  >
                    {link.name}
                  </Button>
                ))}
              </div>
            </SheetBody>
          </Sheet>
        </Grid>
      ))}
    </Grid>
  );
};

export default LinkPage;
