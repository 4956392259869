import RadioButton from "@components/radiobutton/RadioButton";
import Sheet from "@components/sheet/Sheet";
import { projectStorage } from "@config/firebase/config";
import { TimerlineSlider } from "@config/types/timeline.interface";
import { Live, ReduxState } from "@config/types/types";
import { wsContext } from "@config/websocket/WebsocketProvider";
import {
  Button,
  makeStyles,
  TextField,
  IconButton,
  Typography,
  ButtonBase,
} from "@material-ui/core";
import { Delete } from "@material-ui/icons";
import FileFileDownload from "material-ui/svg-icons/file/file-download";
import React, { ChangeEvent, useContext, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import swal from "sweetalert";

const mcs = makeStyles((theme) => ({
  body: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  items: {
    width: "100%",
    display: "grid",
    gap: 10,
    gridTemplateColumns: "repeat(auto-fit, minmax(350px, 1fr))",
  },
  item: {
    padding: 10,
  },
}));

const TimelinePage = () => {
  const c = mcs();
  const [timeline, set] = useState<TimerlineSlider>({
    progress: 0,
    isLive: false,
    items: [
      {
        main: {
          text: "100 CCU",
        },
        value: 100,
        show: false,
      },
      {
        main: {
          text: "200 CCU",
        },
        value: 200,
        show: false,
      },
      {
        main: {
          text: "300 CCU",
        },
        value: 300,
        show: false,
      },
      {
        main: {
          text: "400 CCU",
        },
        value: 400,
        show: false,
      },
    ],
  });
  const { setLiveSettings } = useContext(wsContext);

  const { timeline: timelineWS } = useSelector<ReduxState, Live>((s) => s.live);
  useEffect(() => {
    if (!timelineWS) return;
    set(timelineWS);
  }, [timelineWS]);

  const onProgressChange = (e: ChangeEvent<HTMLInputElement>) => {
    set((state) => ({ ...state, progress: e.target.valueAsNumber || 0 }));
  };

  const handleItemText =
    (index: number, key: "main" | "opposite", key2: "text" | "name" = "text") =>
    (e: ChangeEvent<HTMLInputElement>) => {
      set((state) => ({
        ...state,
        items: state.items.map((item, i) =>
          i === index
            ? { ...item, [key]: { ...item[key], [key2]: e.target.value } }
            : item
        ),
      }));
    };
  const handleItemValue =
    (index: number) => (e: ChangeEvent<HTMLInputElement>) => {
      set((state) => ({
        ...state,
        items: state.items.map((item, i) =>
          i === index ? { ...item, value: e.target.valueAsNumber || 0 } : item
        ),
      }));
    };

  const toggleLive = () => {
    const value = !timelineWS?.isLive;
    setLiveSettings({
      timeline: {
        ...timeline,
        isLive: value,
        items: value
          ? timeline.items.map((item) => ({ ...item, show: false }))
          : timeline.items,
      },
    });
    toast.success(value ? "Showing" : "Hidden");
  };

  const toggleItem = (index: number) => () => {
    setLiveSettings({
      timeline: {
        ...timeline,
        isLive: false,
        items: timeline.items.map((item, i) =>
          i === index ? { ...item, show: !item.show } : { ...item, show: false }
        ),
      },
    });
  };

  const apply = () => {
    setLiveSettings({ timeline });
  };

  const add = () =>
    set((state) => ({
      ...state,
      items: [
        ...state.items,
        {
          main: { text: "999 CCU", image: "" },
          value: 999,
          opposite: { text: "", image: "" },
          show: false,
        },
      ],
    }));

  const deleteItem = (index: number) => () => {
    swal({
      title: "Delete super item?",
      dangerMode: true,
      buttons: ["Cancel", true],
    }).then((result) => {
      if (result) {
        set((state) => ({
          ...state,
          items: state.items.filter((_, i) => i !== index),
        }));
      }
    });
  };

  const fileSelect =
    (index: number, key: "main" | "opposite") =>
    (e: ChangeEvent<HTMLInputElement>) => {
      const image = e.target.files?.length ? e.target.files[0] : null;
      if (image) {
        const ref = projectStorage.ref("slider/" + Date.now());
        const toastRef = toast.loading("Uploading...");
        ref
          .put(image)
          .then(async (snap) => {
            const url = await ref.getDownloadURL();
            toast.success("Uploaded Successfully", { id: toastRef });
            set((state) => {
              const data = {
                ...state,
                items: state.items.map((item, i) =>
                  i === index
                    ? { ...item, [key]: { ...item[key], image: url } }
                    : item
                ),
              };
              setLiveSettings({ timeline: data });
              return data;
            });
          })
          .catch((err) => {
            toast.error("Upload Failed", { id: toastRef });
          });
      }
    };

  return (
    <div className={c.body}>
      <RadioButton
        style={{ alignSelf: "flex-start" }}
        checked={timeline.isLive ?? false}
        onClick={toggleLive}
      >
        Show/Hide
      </RadioButton>
      <div style={{ display: "flex", overflow: "visible", marginTop: 20 }}>
        <TextField
          value={timeline.progress}
          type="number"
          variant="outlined"
          label="progress"
          onChange={onProgressChange}
          size="small"
          inputProps={{ step: 10 }}
        />
        <Button variant="contained" style={{ marginLeft: 10 }} onClick={apply}>
          Apply Changes
        </Button>
        <Button variant="contained" style={{ marginLeft: 10 }} onClick={add}>
          Add Item
        </Button>
      </div>
      <div className={c.items}>
        {timeline.items.map((item, i) => (
          <Sheet className={c.item} key={i}>
            <Sheet style={{ padding: 10, border: "1px solid rgba(0,0,0,.2)" }}>
              <Typography>Top</Typography>
              <ButtonBase
                style={{
                  width: "100%",
                  height: 200,
                  backgroundColor: "rgba(0,0,0,.1)",
                  backgroundSize: "contain",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                  backgroundImage: `url("${item.opposite?.image}")`,
                }}
                component="label"
              >
                <input
                  hidden
                  type="file"
                  onChange={fileSelect(i, "opposite")}
                />
                {!item.opposite?.image && "Upload"}
              </ButtonBase>

              <TextField
                value={item.opposite?.name}
                label="Text"
                variant="outlined"
                size="small"
                style={{ marginTop: 20 }}
                onChange={handleItemText(i, "opposite", "name")}
              />

              <TextField
                value={item.opposite?.text}
                multiline
                rows={5}
                style={{ marginTop: 10 }}
                variant="outlined"
                label="Description"
                onChange={handleItemText(i, "opposite")}
              />
            </Sheet>
            <Sheet style={{ padding: 10, border: "1px solid rgba(0,0,0,.2)" }}>
              <Typography>Bottom</Typography>
              <TextField
                value={item.main.text}
                label="Text"
                variant="outlined"
                size="small"
                style={{ marginTop: 20 }}
                onChange={handleItemText(i, "main")}
              />
              <TextField
                value={item.value}
                label="Value needed"
                style={{ marginTop: 20 }}
                size="small"
                type="number"
                variant="outlined"
                inputProps={{ step: 10 }}
                onChange={handleItemValue(i)}
              />
            </Sheet>
            <Button
              color={item.show ? "primary" : "default"}
              style={{ marginTop: 20 }}
              variant="contained"
              onClick={toggleItem(i)}
              disabled={timeline.progress < item.value}
            >
              Show / Hide
            </Button>
            <IconButton
              style={{ alignSelf: "flex-end", marginTop: 10 }}
              onClick={deleteItem(i)}
              color="secondary"
            >
              <Delete />
            </IconButton>
          </Sheet>
        ))}
      </div>
    </div>
  );
};

export default TimelinePage;
