import React from "react";
import { useSelector } from "react-redux";
import { Live, ReduxState, Participant, Match } from "@type/types";
import { format } from "date-fns";

const useTournament = () => {
  const { match, tournament } = useSelector<ReduxState, Live>(
    (state) => state.live
  );
  const participants = tournament?.participants;
  const matches = tournament?.matches;

  const getOrg = (teamId: number = 0): Participant | undefined => {
    return participants?.find(
      (team) => team.id === teamId || team.group_player_ids.includes(teamId)
    );
  };

  /**
   * ### Get result of the match
   * @param match {@link Match}
   * @param teamId number
   * @returns
   */
  const score = (match?: Match, teamId: number = 0) => {
    if (!match) return { wins: 0, loses: 0, isWinner: false };

    const bestof = match?.bestOf ?? 3;

    let wins = 0,
      loses = 0;

    const team = getOrg(teamId);
    const teamGroupIds = team?.group_player_ids ?? [];

    let isTeam1 =
      teamGroupIds.includes(match.player1_id ?? 0) ||
      team?.id === (match.player1_id ?? 0);

    let rounds = match.scores_csv.split(",");

    rounds.forEach((r) => {
      let ss = r.match(/^(\d*)-(\d*)/);
      if (isTeam1) {
        if (ss && parseInt(ss[1]) > parseInt(ss[2])) {
          wins = wins + 1;
        } else if (ss && parseInt(ss[1]) < parseInt(ss[2])) {
          loses = loses + 1;
        }
      } else {
        if (ss && parseInt(ss[1]) < parseInt(ss[2])) {
          wins = wins + 1;
        } else if (ss && parseInt(ss[1]) > parseInt(ss[2])) {
          loses = loses + 1;
        }
      }
    });

    const isWinner = wins > bestof / 2;
    return { wins, loses, isWinner };
  };

  /**
   * get Match by id
   * @param id
   * @returns
   */
  const getMatch = (id: number) => {
    return matches?.find((m) => m.id === id);
  };

  /**
   * Get winner from a match
   * @param matchId
   * @returns
   */
  const getWinner = (matchId: number) => {
    if (
      score(getMatch(matchId), getMatch(matchId)?.player1_id ?? 0 ?? 0).isWinner
    ) {
      return getOrg(getMatch(matchId)?.player1_id ?? 0 ?? 0);
    } else if (
      score(getMatch(matchId), getMatch(matchId)?.player2_id ?? 0 ?? 0).isWinner
    ) {
      return getOrg(getMatch(matchId)?.player2_id ?? 0 ?? 0);
    } else {
      return undefined;
    }
  };

  /**
   * ### Get team's group stage result.
   * @param teamId
   * @returns
   */
  const getTeamGroupsResult = (
    teamId: number = 0
  ): {
    wins: number;
    loses: number;
    points: number;
  } => {
    const groupIds = getOrg(teamId)?.group_player_ids ?? [];

    let wins = 0,
      loses = 0,
      points = 0;

    matches
      ?.filter(
        (match) =>
          groupIds.includes(match.player1_id ?? 0) ||
          groupIds.includes(match.player2_id ?? 0)
      )
      .forEach((match) => {
        let isTeam1 = groupIds.includes(match.player1_id ?? 0);

        let matchWins = 0;
        let matchLoses = 0;

        let rounds = match.scores_csv.split(",");

        rounds.forEach((r) => {
          let ss = r.match(/^(\d*)-(\d*)/);
          if (isTeam1) {
            if (ss && parseInt(ss[1]) > parseInt(ss[2])) {
              wins = wins + 1;
              matchWins = matchWins + 1;
            } else if (ss && parseInt(ss[1]) < parseInt(ss[2])) {
              loses = loses + 1;
              matchLoses = matchLoses + 1;
            }
          } else {
            if (ss && parseInt(ss[1]) < parseInt(ss[2])) {
              wins = wins + 1;

              matchWins = matchWins + 1;
            } else if (ss && parseInt(ss[1]) > parseInt(ss[2])) {
              loses = loses + 1;
              matchLoses = matchLoses + 1;
            }
          }
        });

        if (matchWins === 2 && matchLoses === 0) {
          points = points + 3;
        } else if (matchWins === 1) {
          points = points + 1;
        }
      });

    return { wins, loses, points };
  };

  const badger = (m: Match) => {
    const bestof = m.bestOf ?? 2;
    if (
      score(m, m.player1_id ?? 0).wins !== 0 ||
      score(m, m.player2_id ?? 0).wins !== 0
    ) {
      if (score(m, m.player1_id ?? 0).wins > bestof / 2) {
        return `#${getOrg(m.player1_id ?? 0)?.university_acronym}WIN`;
      }

      if (score(m, m.player2_id ?? 0).wins > bestof / 2) {
        return `#${getOrg(m.player2_id ?? 0)?.university_acronym}WIN`;
      }
      return `${score(m, m.player1_id ?? 0).wins} - ${
        score(m, m.player2_id ?? 0).wins
      }`;
    } else {
      return format(new Date(m.schedule ?? Date.now()), "hh:mm a") ?? "SOON";
    }
  };

  return {
    match,
    tournament,
    participants: participants ?? [],
    matches: matches ?? [],
    team: getOrg,
    getTeamGroupsResult,
    score,
    badger,
    getWinner,
    getMatch,
  };
};

export default useTournament;
