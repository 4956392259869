import {
  Button,
  Dialog,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import React from "react";
import { wsContext } from "../../config/websocket/WebsocketProvider";
import Sheet from "../sheet/Sheet";
import SheetBody from "../sheet/SheetBody";
import SheetFooter from "../sheet/SheetFooter";
import SheetHead from "../sheet/SheetHead";
import SheetHeadTitle from "../sheet/SheetHeadTitle";
import { useAuthState } from "react-firebase-hooks/auth";
import firebase, { projectAuth } from "@config/firebase/config";

interface WebsocketDialogProps {
  open: boolean;
}

const ms = makeStyles((theme) => ({
  dialog: {
    display: "flex",
    flexDirection: "column",
  },
  buttonContainer: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gap: theme.spacing(2),
    padding: theme.spacing(2, 0),
  },
  paper: {
    // boxShadow: "none",
    filter: "drop-shadow(0px 4px 8px rgba(0,0,0,.25))",
    backgroundColor: "transparent",
  },
}));

const rooms = [
  { name: "UAC", roomId: "uac" },
  { name: "NCO", roomId: "nco" },
  { name: "PVP", roomId: "pvp" },
];

type UserProps = [firebase.User, boolean, firebase.auth.Error];

const WebsocketDialog: React.FC<WebsocketDialogProps> = ({ open }) => {
  const classes = ms();
  const [userData]: UserProps = useAuthState(projectAuth);
  const ws = React.useContext(wsContext);

  const user = userData?.email || "Controller";

  return (
    <Dialog open={open} classes={{ paper: classes.paper }}>
      <Sheet>
        <SheetHead>
          <SheetHeadTitle>Session Rooms</SheetHeadTitle>
        </SheetHead>
        <SheetBody>
          <div className={classes.dialog}>
            <div className={classes.buttonContainer}>
              {rooms.map((room) => (
                <Button
                  key={room.roomId}
                  fullWidth
                  variant="outlined"
                  onClick={() => ws.joinRoom(room.roomId, user)}
                >
                  {room.name}
                </Button>
              ))}
            </div>
          </div>
        </SheetBody>
        <SheetFooter>Select a session room</SheetFooter>
      </Sheet>
    </Dialog>
  );
};

export default WebsocketDialog;
