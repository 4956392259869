import {
  ButtonBase,
  Fab,
  makeStyles,
  CircularProgress,
  Dialog,
} from "@material-ui/core";
import React, { FC } from "react";
import fbase, { projectStorage } from "@config/firebase/config";
import { Caster } from "@config/types/caster.interface";
import { green } from "@material-ui/core/colors";
import Sheet from "@components/sheet/Sheet";
import SheetHead from "@components/sheet/SheetHead";
import { Add } from "@material-ui/icons";
import SheetHeadTitle from "@components/sheet/SheetHeadTitle";
import Check from "@material-ui/icons/Check";
import SaveOutlined from "@material-ui/icons/SaveOutlined";
import SheetBody from "@components/sheet/SheetBody";
import TextField from "@components/textfield/TextField";

interface AddDialogProps {
  open: boolean;
  onClose: () => void;
  tournamentRef: fbase.firestore.DocumentReference;
}

const makeDialogStyles = makeStyles((theme) => ({
  dialogPaper: {
    backgroundColor: "transparent",
    boxShadow: "none",
    overflow: "visible",
  },
  inputWrapper: {
    display: "flex",

    "& text-inputs": {
      display: "flex",
      flexDirection: "column",
    },

    "& .btn": {
      width: 122,
      border: "1px solid rgba(0,0,0,.25)",
      borderRadius: 4,
      flexShrink: 0,
      marginRight: theme.spacing(2),
      backgroundSize: "cover",
      backgroundPosition: "center",
    },
  },

  headWrapper: {
    display: "flex",
    alignItems: "center",
    "& h5": {
      flex: 1,
    },
  },
  fabWrapper: {
    margin: theme.spacing(1),
    position: "relative",
  },
  fabProgress: {
    color: green[500],
    position: "absolute",
    top: -6,
    left: -6,
    zIndex: 1,
  },
}));

const fileTypes = [
  "image/png",
  "image/jpeg",
  "image/gif",
  "image/svg",
  "image/svg+xml",
];

const AddDialog: React.FC<AddDialogProps> = ({
  open,
  onClose,
  tournamentRef,
}) => {
  const classes = makeDialogStyles();
  const [loader, setLoader] = React.useState<{
    photo_url: boolean;
    photo_upload_progress: number;
  }>({
    photo_url: false,
    photo_upload_progress: 0,
  });
  const [saveLoading, setSaveLoading] = React.useState<boolean>(false);
  const [success, setSuccess] = React.useState<boolean>(false);

  const [form, setForm] = React.useState<Caster>({
    name: "",
    ign: "",
    photo_url: "",
  });

  const handleChange = ({
    currentTarget: { name, value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    setForm({ ...form, [name]: value });
  };

  const fileSelect =
    (destination: string = "Casters/") =>
    ({ target: { files, name } }: React.ChangeEvent<HTMLInputElement>) => {
      const image = files?.length ? files[0] : null;
      if (image) {
        uploadFile({ file: image, destination, name });
      }
    };

  const uploadFile = ({
    file,
    destination,
    name,
  }: {
    file: File;
    destination: string;
    name: string;
  }) => {
    const storageRef = projectStorage.ref(
      destination + Date.now + "_" + file.name
    );

    storageRef.put(file).on(
      "state_change",

      // Upload state change event
      (snapshot) => {
        let percentage = Math.ceil(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setLoader({
          ...loader,
          [name + "_upload_progress"]: percentage,
          [name]: true,
        });
      },

      //  on error event
      (error) => {
        setLoader({
          ...loader,
          [name + "_upload_progress"]: 0,
          [name]: false,
        });
      },

      //  on upload finish
      async () => {
        const url = await storageRef.getDownloadURL();
        setLoader({
          ...loader,
          [name + "_upload_progress"]: 0,
          [name]: false,
        });
        setForm({
          ...form,
          [name]: url,
          photo_type: file.type,
          // [name + "_ref"]: destination + file.name,
        });
      }
    );
  };

  const save = () => {
    setSaveLoading(true);
    tournamentRef
      .collection("live")
      .doc("casters")
      .set(
        {
          list: fbase.firestore.FieldValue.arrayUnion(form),
        },
        { merge: true }
      )
      .then(() => {
        setSaveLoading(false);
        closeRemarks();
      });
  };

  const closeRemarks = () => {
    setForm({ name: "", ign: "", photo_url: "" });
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={closeRemarks}
      maxWidth="xs"
      fullWidth
      classes={{ paper: classes.dialogPaper }}
    >
      <Sheet loading={loader.photo_url}>
        <SheetHead icon={<Add fontSize="large" />}>
          <div className={classes.headWrapper}>
            <SheetHeadTitle>Add Caster</SheetHeadTitle>
            <div className={classes.fabWrapper}>
              <Fab
                color="primary"
                onClick={save}
                disabled={!Boolean(form.name) || !Boolean(form.ign)}
              >
                {success ? (
                  <Check fontSize="large" />
                ) : (
                  <SaveOutlined fontSize="large" />
                )}
              </Fab>
              {saveLoading && (
                <CircularProgress size={68} className={classes.fabProgress} />
              )}
            </div>
          </div>
        </SheetHead>
        <SheetBody>
          <div className={classes.inputWrapper}>
            <ButtonBase
              className="btn"
              component="label"
              style={{
                backgroundImage: `url("${form.photo_url}")`,
                border: form.photo_url ? "none" : "1px solid rgba(0,0,0,.25)",
              }}
            >
              <input
                type="file"
                hidden
                name="photo_url"
                accept={fileTypes.join(", ")}
                onChange={fileSelect("Casters/")}
              />
              {!Boolean(form.photo_url) && "Photo"}
            </ButtonBase>
            <div className="text-inputs">
              <TextField
                style={{ marginTop: 0 }}
                disabled={loader.photo_url}
                fullWidth
                name="name"
                label="Name"
                onChange={handleChange}
              />
              <TextField
                fullWidth
                label="IGN"
                name="ign"
                disabled={loader.photo_url}
                onChange={handleChange}
              />
            </div>
          </div>
        </SheetBody>
      </Sheet>
    </Dialog>
  );
};

export default AddDialog;
