import React from "react";
import { Grid } from "@material-ui/core";
import LowerThirds from "./LowerThirds";
import MatchControl from "./Match";

const LowerThirdsGrid = () => {
  return (
    <div>
      <Grid container spacing={6}>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={4}>
          <LowerThirds />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={4}>
          <MatchControl />
        </Grid>
      </Grid>
    </div>
  );
};

export default LowerThirdsGrid;
